


import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect'
import Plx from 'react-plx'
import * as Scroll from 'react-scroll'

import Container from '../components/Container'
import useDocDimensions from '../hooks/useDocDimensions'
import ImageLoader from '../components/images/ImageLoader'

let scroll = Scroll.animateScroll;


function LookbookImage(props) {

  return (
    <Container
      style={{
        width: `${props.width}px`,
        height: `${props.width}px`,
        background: 'none',
        position: 'relative',
        alignItems: props.image.first ? 'flex-start' : 'center',
        //filter: props.image.first ? null : `drop-shadow(4px 4px 8px #333)`
      }}
    >
      <ImageLoader src={props.image.url} gray style={{ height: '60%', maxWidth: '80%', objectFit: 'contain' }} />
      {/*<img src={props.image} alt='piece' style={} />*/}
    </Container>
  )
}

function Lookbook({ images, name }) {

  const { width, height } = useDocDimensions()

  const backgroundParralax = {
    start: '0%',
    end: '100%',
    properties: [
      {
        startValue: '#fff',
        endValue: '#000',
        property: 'backgroundColor'
      }
    ]
  }

  const parallaxDataIn = {
    duration: '400px',
    easing: 'easeInCubic',
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
      {
        startValue: 0.5,
        endValue: 1,
        property: 'scale',
      },

      {
        property: 'translateX',
        startValue: -width,
        endValue: 0
      }

    ]
  }

  const parallaxDataOut = {
    duration: '400px',
    easing: 'easeOutCubic',
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
      {
        startValue: 1,
        endValue: 0.5,
        property: 'scale',
      },

      {
        property: 'translateX',
        startValue: 0,
        endValue: width
      }

    ]
  }

  const thumbnailParallaxIn = {
    duration: '400px',
    easing: 'easeInCubic',
    properties: [
      {
        startValue: 100,
        endValue: 0,
        property: 'grayscale',
      },
      {
        startValue: 0.9,
        endValue: 1,
        property: 'scale',
      },
    ]
  }

  const thumbnailParallaxOut = {
    duration: '400px',
    easing: 'easeOutCubic',
    properties: [
      {
        startValue: 0,
        endValue: 100,
        property: 'grayscale',
      },
      {
        startValue: 1,
        endValue: 0.9,
        property: 'scale',
      },
    ]
  }

  const goToNext = index => {
    const nextIndex = index + 1
    if (nextIndex >= images.length) {
      // scroll.scrollToTop({
      //   duration: images.length * 800,
      //   smooth: "easeOutCubic",
      //   ignoreCancelEvents: true
      // })
      // return
    }
    else {
      const nextPos = height * nextIndex
      //setCurrentPos(nextPos)
      scroll.scrollTo(nextPos,
        {
          duration: 1000,
          smooth: "easeOutCubic",
          ignoreCancelEvents: true
        })
    }
  }


  const goToPrev = index => {
    const prevIndex = index - 1
    if (prevIndex < 0) {
      // scroll.scrollToBottom({
      //   duration: images.length * 800,
      //   smooth: "easeOutCubic",
      //   ignoreCancelEvents: true
      // })
      // return
    }
    else {
      const prevPos = height * prevIndex
      //setCurrentPos(prevPos)
      scroll.scrollTo(prevPos,
        {
          duration: 1000,
          smooth: "easeOutCubic",
          ignoreCancelEvents: true
        })
    }
  }


  useEffect(() => {
    if (images) {
      scroll.scrollTo(Math.floor(images.length / 2) * height, {
        duration: 1250,
        smooth: "easeInOutCubic",
        ignoreCancelEvents: true
      })
    }
  }, [images])

  useEffect(() => {

  }, [])

  return (
    <Plx
      parallaxData={[backgroundParralax]}
    >
      <Container
        style={{
          position: 'relative',
          flexFlow: 'column',
          justifyContent: 'flex-start',
          height: images.length * height,
        }}
      >
        <Container
          style={{
            position: 'fixed',
            top: '0px',
            color: 'rgb(127,127,127)',
            height: isMobile ? '80px' : '100px',
            fontSize: isMobile ? '24px' : '32px',
            fontFamily: 'Oswald'
          }}
        >
          LOOKBOOK
        </Container>
        {
          images ?
            images.map((image, index) =>
              <Plx
                key={`parallax_image_${index}`}
                name=''
                style={{
                  minHeight: height,
                  height: height,
                  minWidth: '100vw',
                  display: 'flex',
                  justifyContent: 'center',
                  //opacity: index === 0 ? 1 : 0
                }}
                parallaxData={[
                  { ...parallaxDataIn, start: `${(index - 1) * height}px`, duration: height },
                  { ...parallaxDataOut, start: `${(index) * height}px`, duration: height }
                ]}
              >
                <LookbookImage width={height} numImages={images.length} index={index} image={image} />
                <Container
                  onClick={() => goToNext(index)}
                  style={{
                    position: 'absolute',
                    left: '0px',
                    width: '50vw',
                    top: '0px'
                  }}
                />
                <Container
                  onClick={() => goToPrev(index)}
                  style={{
                    position: 'absolute',
                    right: '0px',
                    width: '50vw',
                    top: '0px'
                  }}
                />
              </Plx>
            )
            :
            null
        }
      </Container>
    </Plx>

  )
}

export default Lookbook