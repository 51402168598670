import React, { useState, useEffect, useRef, useContext, useCallback } from 'react'

import useDocDimensions from '../../hooks/useDocDimensions'

import Container from '../Container'
import PopUpsContext from '../../contexts/PopUpsContext'

import * as Scroll from 'react-scroll'

const scroll = Scroll.animateScroll


function SlidingMenuItem({ tabName, show, selectTab }) {
    
    const [justClicked, setJustClicked] = useState(false)

    const handleClick = () => {
        selectTab(tabName)
        setJustClicked(true)
    }

    useEffect(() => {
        if(justClicked){
            setTimeout(() => setJustClicked(false), 100)
        }
    }, [justClicked])

    return (
        <Container
            onClick={handleClick}
            key={`admin_menu_tab_selector_${tabName}`}
            style={{
                position: 'relative',
                height: '50px',
                padding: '0px 20px',
                background: justClicked ? '#ababab' : '#ffffff',
                opacity: show ? 1 : 0,
                transition: 'all 250ms ease-out',
                transitionDelay: show ? '400ms' : '0ms'
            }}
        >
            {tabName.toUpperCase()}
        </Container>
    )
}

function SlidingMenu({ tabs, active, setActive, selectTab, selectedTab }) {

    return (
        <Container
            style={{
                position: 'fixed',
                zIndex: '2',
                pointerEvents: active ? 'auto' : 'none',
            }}
        >
            <Container onClick={() => setActive(false)} style={{ position: 'fixed', pointerEvents: active ? 'auto' : 'none', opacity: active ? 1 : 0, background: 'rgba(0,0,0,0.6)', transition: 'opacity 500ms' }} />
            <Container
                style={{
                    right: '0px',
                    top: '60px',
                    bottom: '0px',
                    height: null,
                    overflow: 'hidden',
                    width: 200,
                    transform: `translateX(${active ? 0 : 200}px)`,
                    transition: 'transform 500ms',
                    flexFlow: 'column',
                    justifyContent: 'flex-start',
                    background: 'white'
                }}
            >
                {
                    Object.keys(tabs).map(tabName =>
                        <SlidingMenuItem selectTab={selectTab} tabName={tabName} show={active} />
                    )
                }
            </Container>
        </Container>
    )
}


function SlidingMenuRouter({ pages }) {

    const [menuVisible, setMenuVisible] = useState(true)
    const [menuActive, setMenuActive] = useState(false)
    const [selectedTab, setSelectedTab] = useState(null)
    const [prevScroll, setPrevScroll] = useState(0)
    const { width, height } = useDocDimensions()

    const { showMessage } = useContext(PopUpsContext)

    const routerContainer = useRef()

    useEffect(() => {
        if (pages && !selectedTab) {
            setSelectedTab(Object.keys(pages)[0])
        }
    }, [pages])

    const handleMenuClick = useCallback(() => {
        if(menuActive){
            setMenuActive(false)
        }
        else{
            scroll.scrollTo(0, {
                duration: 100,
                containerId: 'router_container'
            })
            setTimeout(() => setMenuActive(true), 100)
        }
    }, [menuActive])

    const selectTab = tabName => {

        setSelectedTab(tabName)
        setMenuActive(false)
    }

    return (
        <Container
            ref={routerContainer}
            id='router_container'
            style={{
                overflowY: 'auto',
                overflowX: 'hidden',
                fontFamily: 'Oswald',
                justifyContent: 'flex-start',
                flexFlow: 'column',
            }}
        >
            <Container
                style={{
                    position: 'relative',
                    zIndex: '3',
                    fontSize: '20px',
                    background: 'black',
                    color: 'white',
                    overflow: 'hidden',
                    height: '60px',
                    maxHeight: menuVisible ? '60px' : '0px',
                    //transform: `translateY(${menuVisible ? 0 : -60}px)`,
                    transition: 'max-height 500ms',
                }}
            >
                {selectedTab ? selectedTab.toUpperCase() : ''}
                <Container
                    onClick={handleMenuClick}
                    style={{
                        pointerEvents: 'auto',
                        right: '0px',
                        width: '60px'
                    }}
                >
                    <img alt='menu' src='/images/icons/hamburger_menu.png' style={{ height: '40%', objectFit: 'contain', filter: 'invert(100%)' }} />
                </Container>
            </Container>
            <Container
                style={{
                    position: 'relative',
                    alignItems: 'flex-start',
                    height: 'auto'
                }}
            >
                {
                    Object.keys(pages).map(tabName =>
                        <Container
                            key={`admin_menu_tab_container_${tabName}`}
                            style={{
                                height: 'auto',
                                maxHeight: tabName === selectedTab ? null : '100%',
                                //position: 'relative',
                                opacity: tabName === selectedTab ? 1 : 0,
                                pointerEvents: tabName === selectedTab ? 'auto' : 'none',
                                zIndex: tabName === selectedTab ? 1 : 0,
                                transition: 'opacity 500ms'
                            }}
                        >
                            {
                                React.createElement(pages[tabName].component, { ...pages[tabName].data, setMenuVisible })
                            }
                        </Container>

                    )
                }
            </Container>

            <SlidingMenu active={menuActive} setActive={setMenuActive} tabs={pages} selectTab={selectTab} selectedTab={selectedTab} />
        </Container>
    )
}

export default SlidingMenuRouter