
import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { isMobile } from 'react-device-detect'

import Container from '../Container'
import PageMenu from './PageMenu'
import MobilePageMenu from './MobilePageMenu'

function CheckoutButton({ show, history, numItems }) {
  return (
    <Container
      onClick={() => history.push('/checkout')}
      style={{
        position: 'fixed',
        top: `0px`,
        right: `0px`,
        fontSize: '72px',
        color: 'black',
        fontFamily: 'Oswald',
        width: isMobile ? '80px' : '60px',
        height: `${isMobile ? 80 : 60}px`,
        transition: 'opacity 250ms ease-out',
        pointerEvents: show ? 'auto' : 'none',
        opacity: show ? '0.8' : '0',
        fontSize: '32px'
      }}
    >
      💸
  </Container>
  )
}

CheckoutButton = withRouter(CheckoutButton)

function PageRouter({ loaded, pages, location, lookbookPieces, cart }) {
  const pageNames = Object.keys(pages)
  
  const [numDots, setNumDots] = useState(1)

  //console.log(location)

  const dotInterval = useRef()

  const handleNextDot = () => {
    setNumDots(numDots => numDots%3 + 1)
  }

  useEffect(() => {
    if(!loaded){
      dotInterval.current = setInterval(handleNextDot, 333)
    }
    else{
      setTimeout(() => clearInterval(dotInterval.current), 500)
    }
  }, [loaded])
  

  return (
    <Container
      style={{
        position: 'relative',
        height: 'auto',
        minHeight: '100%',
        alignItems: 'flex-start',
      }}
    >
      <Container
        style={{
          position: 'fixed',
          opacity: loaded ? 0 : 1,
          fontSize: '24px',
          background:'black',
          color: 'white',
          fontFamily: 'Oswald',
          transition: 'opacity 1s ease-out',
          pointerEvents: 'none'
        }}
      >
        {`Loading${'.'.repeat(numDots)}`}
      </Container>
      <Container
        style={{
          position: 'relative',
          height: 'auto',
          alignItems: 'flex-start',
          opacity: loaded ? 1 : 0,
          transition: 'opacity 1s ease-out'
        }}
      >
        <TransitionGroup
          style={{
            position: 'relative',
            height: 'auto',
            alignItems: 'flex-start',
            width: '100%',
          }}
        >
          <CSSTransition
            key={location.key}
            timeout={300}
            classNames='page'
          >
            <Switch location={location}>
              {

                pageNames.map(pageName =>
                  <Route key={`route_for_${pageName.toLowerCase()}`} exact={pages[pageName].path === '/'} path={pages[pageName].path} render={() => React.createElement(pages[pageName].component, { ...pages[pageName].data })} />
                )
              }
            </Switch>
          </CSSTransition>
        </TransitionGroup>
        <CheckoutButton show={cart.length} numItems={cart.length} />
        <MobilePageMenu pages={pages} />
      </Container>
    </Container>
  )
}

export default withRouter(PageRouter)
