import React, {useState, useRef, useMemo} from 'react';
import Container from '../Container'
import { TextInput } from '../Inputs';

function BillingSection({ name, fields, handleChange }) {
    const [active, setActive] = useState(false)
  
    const fieldsSection = useRef()
  
    const containerHeight = useMemo(() => active ? fieldsSection.current.clientHeight + 90 : 90, [active, fieldsSection])
    console.log(containerHeight)
    return (
      <Container
        style={{
          position: 'relative',
          height: 'auto',
          transition: 'height 250ms ease-out',
          flexFlow: 'column',
          overflow: 'hidden',
          justifyContent: 'flex-start',
          borderBottom: '1px solid #cdcdcd'
        }}
      >
        <Container
          style={{
            position: 'relative',
            height: '50px',
            minHeight: '50px',
            fontFamily: 'Oswald',
            fontSize: '24px',
            //alignItems: 'flex-end'
          }}
        >
          {name}
        </Container>
        <Container
          style={{
            position: 'relative',
            height: '40px',
            minHeight: '40px'
          }}
        >
          <Container
            style={{
              right: '50px',
              width: 'auto',
              fontFamily: 'Oswald',
              justifyContent: 'flex-end'
            }}
          >
            Same as shipping
          </Container>
          <Container
            onClick={() => setActive(active => !active)}
            style={{
              right: '0px',
              width: '50px'
            }}
          >
            <Container
              style={{
                height: '20px',
                width: '20px',
                border: '1px solid #cdcdcd'
              }}
            >
              <Container
                style={{
                  height: '18px',
                  width: '18px',
                  background: '#ababab',
                  opacity: active ? 0 : 1
                }}
              />
            </Container>
          </Container>
        </Container>
        <Container
          ref={fieldsSection}
          style={{
            position: 'relative',
            height: active ? fields.length * 60 : 0,
            opacity: active ? 1 : 0,
            transition: 'all 250ms ease-out',
            flexFlow: 'column',
            justifyContent: 'flex-start'
          }}
        >
          {
            fields.map((field, index) =>
              <Container
                key={`billing_section_field_${index}`}
                style={{
                  position: 'relative',
                  height: '60px',
                  width: '90%'
                }}
              >
                <TextInput style={{ fontFamily: 'Oswald', fontSize: '20px' }} required={field.required} label={field.label} name={field.name} autocomplete={field.autocomplete} value={field.value} onChange={value => handleChange(name, index, value)} />
              </Container>
            )
          }
        </Container>
        <Container
          style={{
            position: 'relative',
            height: '20px',
            fontFamily: 'Oswald',
            fontSize: '24px',
            alignItems: 'flex-end'
          }}
        />
      </Container>
    )
  }
  
  export default BillingSection