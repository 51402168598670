import React, { useState, useEffect, useMemo, useCallback } from 'react'

import utils from '../../utils/pieceUtils'

const getPieceIndex = (pieces, searchedPiece) => {
    let matchingPieces = pieces.filter(piece => piece.id === searchedPiece.id)
    
    if(matchingPieces.length){
        let pieceIndex = pieces.indexOf(matchingPieces[0])
        return pieceIndex
    }
    return -1
}

function usePieces() {
    const [pieces, setPieces] = useState(null)

    
    const renameSeason = (oldName, newName, callback) => {
        utils.renameSeason(oldName, newName, res => {
            if (res) {
                updateSeasonNames(oldName, newName)
                callback({success: true})
                //getPieceById(id, piece => updatePiece(piece))
            }
            else{
                callback({error: 'Error renaming season'})
            }
        })
    }


    const deletePiece = useCallback((oldPiece, callback) => {

        utils.deletePiece(oldPiece, ({ error }) => {
            if (error) {
                callback({error})
            }
            else {
                let pieceIndex = getPieceIndex(pieces, oldPiece)
                if(pieceIndex !== 1){
                    let newPieces = [...pieces]
                    newPieces.splice(pieceIndex, 1)
                    setPieces(newPieces)
                    callback({success: true})
                }
                else{
                    callback({error: true})
                }
            }
        })
    }, [pieces])

    const saveNewPiece = useCallback((piece, callback) => {
        utils.saveNewPiece(piece, ({error, updatedPiece}) => {
            if(updatedPiece){
                let newPieces = [...pieces, updatedPiece]
                setPieces(newPieces)
                callback({updatedPiece})
            }
            else{
                callback({error: true})
            }
        })
    }, [pieces])

    const savePiece = useCallback((piece, callback) => {
        utils.savePiece(piece, ({error, updatedPiece}) => {
            if(updatedPiece){
                let pieceIndex = getPieceIndex(pieces, piece)
                if(pieceIndex !== -1){
                    let newPieces = [...pieces]
                    newPieces.splice(pieceIndex, 1, updatedPiece)
                    setPieces(newPieces)
                    callback({updatedPiece})
                }
            }
            else{
                callback({error: true})
            }
        })
    }, [pieces])

    useEffect(() => {
        utils.getPieces(pieces => setPieces(pieces))
    }, [])


    const updatePiece = useCallback((newPiece, callback) => {
        let pieceIndex = -1
        // if (selectedPiece) {
        //     if (newPiece.id === selectedPiece.id) {
        //         setSelectedPiece(newPiece)
        //     }
        // }
        //console.log(newPiece)
        for (let i = 0; i < pieces.length; i++) {
            if (pieces[i].id === newPiece.id) {
                pieceIndex = i
            }
        }
        let newPieces = [...pieces]
        if (pieceIndex !== -1) {
            newPieces.splice(pieceIndex, 1, newPiece)
            //console.log(newPieces[pieceIndex])
        }
        else {
            newPieces.push(newPiece)
            //console.log(newPieces[newPieces.length - 1])
        }
        setPieces(newPieces)

    }, [pieces])



    const updateSeasonNames = useCallback((oldName, newName) => {

        const changeItemSeasonNames = () => {
            let newPieces = [...pieces]
            for (let i = 0; i < newPieces.length; i++) {
                if (newPieces[i].seasonName === oldName) {
                    newPieces[i].seasonName = newName
                }
            }
            setPieces(newPieces)
        }

        if(oldName !== newName){
            changeItemSeasonNames()
        }

    }, [pieces])

    const seasonNames = useMemo(() => pieces ? utils.getSeasonNames(pieces) : [], [pieces])
    
    const { seasons, freePieces } = useMemo(() => pieces ? utils.getNestedPieces(pieces) : ({ seasons: null, freePieces: null }))

    const adminPieceFunctions = useMemo(() => ({
        savePiece, 
        saveNewPiece,
        deletePiece,
        renameSeason,
        updateSeasonNames
    }))

    return { pieces, seasons, freePieces, seasonNames, adminPieceFunctions }
}

export default usePieces