import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import { update } from 'immutability-helper'
import { isMobile } from 'react-device-detect'
import Container from '../Container'
import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/functions'

import FormSection from './FormSection'
import BillingSection from './BillingSection'
import { StripeProvider, Elements, injectStripe, CardElement } from 'react-stripe-elements'
import PopUpsContext from '../../contexts/PopUpsContext'
import PiecesContext from '../../contexts/PiecesContext'
import useDocDimensions from '../../hooks/useDocDimensions';
import { setCartSold } from '../../utils/pieceUtils'

var functions = firebase.functions()
let createCharge = functions.httpsCallable('createCharge')

const purchaseMailUrl = 'https://95w0toykg1.execute-api.us-east-1.amazonaws.com/prod/send'

const headerHeight = isMobile ? 80 : 100

const billingAddressFields = [
    {
        name: 'billing-address',
        //autocomplete: 'shipping street-address',
        label: 'Street Address',
        value: ''
    },
    {
        name: 'billing-address-2',
        //autocomplete: 'shipping street-address-2',
        label: 'Street Address Line 2',
        value: ''
    },
    {
        name: 'billing-city',
        //autocomplete: 'shipping locality',
        label: 'City',
        value: ''
    },
    {
        name: 'billing-state',
        //autocomplete: 'shipping region',
        label: 'State',
        value: ''
    },
    {
        name: 'billing-zip',
        //autocomplete: 'shipping postal-code',
        label: 'ZIP',
        value: ''
    },
    {
        name: 'billing-country',
        //autocomplete: 'shipping country',
        label: 'Country',
        value: ''
    }
]

const addressFields = [
    {
        required: true,
        name: 'ship-address',
        autocomplete: 'shipping street-address',
        label: 'Street Address',
        value: ''
    },
    {
        name: 'ship-address-2',
        //autocomplete: 'shipping street-address-2',
        label: 'Street Address Line 2',
        value: ''
    },
    {
        required: true,
        name: 'ship-city',
        autocomplete: 'shipping locality',
        label: 'City',
        value: ''
    },
    {
        required: true,
        name: 'ship-state',
        autocomplete: 'shipping region',
        label: 'State',
        value: ''
    },
    {
        required: true,
        name: 'ship-zip',
        autocomplete: 'shipping postal-code',
        label: 'ZIP',
        value: ''
    },
    {
        required: true,
        name: 'ship-country',
        autocomplete: 'shipping country',
        label: 'Country',
        value: ''
    }
]


const formFields = {
    'Contact': [
        {
            required: true,
            name: 'name',
            autocomplete: 'name',
            label: 'Name',
            value: ''
        },
        /*
        {
          required: true,
          name: 'lname',
          autocomplete: 'family-name',
          label: 'Last Name',
          value: ''
        },
        */
        {
            required: true,
            name: 'email',
            label: 'Email',
            value: ''
        }
    ],
    'Shipping': addressFields,
    'Billing': billingAddressFields,
    //'Payment': cardFields,
}


function NewCardForm({ cart, stripeActive, setStripeActive, goBack, total, stripe, fields, resetFields, enabled }) {

    const [complete, setComplete] = useState(false)
    const [errorMessage, setErrorMessage] = useState(false)
    const { showMessage, setLoading, setLoadingMessage, prompt } = useContext(PopUpsContext)
    const {reloadPieces} = useContext(PiecesContext)

    const cardRef = useRef()
    var style = {
        base: {
            color: '#2b2b2b',
            height: '40px',
            fontSize: '16px',
            fontFamily: '"Oswald", sans-serif',
            //fontSmoothing: 'antialiased',
            padding: '20px',
            '::placeholder': {
                color: '#cdcdcd',
            },
        },
        invalid: {
            color: '#e5424d',
            ':focus': {
                color: '#303238',
            },
        },
    };

    const handleReady = e => {
        console.log(e)
    }

    const handleComplete = useCallback(() => {
        console.log(cardRef)
        const cardFields = {}

        const getRequestData = fields => {
            let cardFields = {}
            let shippingFields = {}
            let billingFields = {}
            fields['Contact'].forEach(field => {
                if (field.name === 'name') {
                    cardFields.name = field.value
                }
            })
            fields['Shipping'].forEach(field => {
                if (field.value) {
                    switch (field.name) {
                        case 'ship-address':
                            shippingFields.address_line1 = field.value
                            break
                        case 'ship-address-2':
                            shippingFields.address_line2 = field.value
                            break
                        case 'ship-city':
                            shippingFields.address_city = field.value
                            break
                        case 'ship-state':
                            shippingFields.address_state = field.value
                            break
                        case 'ship-zip':
                            shippingFields.address_zip = field.value
                            break
                        case 'ship-country':
                            shippingFields.address_country = field.value
                        default:
                            break
                    }
                }
            })

            fields['Billing'].forEach(field => {
                if (field.value) {
                    switch (field.name) {
                        case 'billing-address':
                            billingFields.address_line1 = field.value
                            break
                        case 'billing-address-2':
                            billingFields.address_line2 = field.value
                            break
                        case 'billing-city':
                            billingFields.address_city = field.value
                            break
                        case 'billing-state':
                            billingFields.address_state = field.value
                            break
                        case 'billing-zip':
                            billingFields.address_zip = field.value
                            break
                        case 'billing-country':
                            billingFields.address_country = field.value
                        default:
                            break
                    }
                }
            })
            if (!billingFields.address_line1) {
                billingFields = shippingFields
            }

            return ({ ...billingFields, ...cardFields })
        }
        setLoading(true)
        setLoadingMessage('Processing payment...')
        stripe.createToken({ type: 'card', ...getRequestData(fields) }).then(res => {
            if (res.error) {
                console.log(res.error)
                showMessage('Something went wrong processing your payment')
                setLoading(false)
                console.log(res.token)
            }
            else {
                createCharge({ token: res.token, amount: Math.round(total*100)}).then(res => {
                    if (res.error) {
                        console.log(res.error)
                        showMessage('Something went wrong with payment, your card was not charged')
                        setLoading(false)
                    }
                    else {
                        const charge = res.data
                        if (charge.paid) {
                            setLoadingMessage('Updating pieces...')
                            setCartSold(cart, res => {
                                setLoadingMessage('Sending us an email...')
                                console.log(res)
                                axios.post(purchaseMailUrl, { contact: fields, cart, itemsUpdated: res }).then(res => {
                                    if (res.data && res.data.success) {
                                        showMessage(`Thanks, we'll send you a confirmation email soon`)
                                        resetFields()
                                        reloadPieces()
                                        setLoading(false)
                                        console.log(res)
                                    }
                                    else {
                                        showMessage(`Something went wrong with our notification system. Hit me up on instagram and let me know what you bought`)
                                        resetFields()
                                        setLoading(false)
                                    }
    
                                })
                                    .catch(err => {
                                        showMessage(`Something went wrong with our notification system. Hit me up on instagram and let me know what you bought`)
                                        resetFields()
                                        setLoading(false)
                                        console.log(err)
                                    })
                            })
                        }
                        else {
                            console.log(charge)
                            setLoading(false)
                            showMessage('Something went wrong with payment, your card was not charged')
                        }
                    }
                })
                    .catch(err => {
                        console.log(err)
                    })
            }
        })
    }, [fields])

    const handleChange = e => {
        if (e.complete) {
            setComplete(true)
        }
        else {
            setComplete(false)
        }
        if (e.error) {
            setErrorMessage(e.error.message)
        }
        else {
            setErrorMessage(null)
        }
    }

    return (

        <Container
            style={{
                position: 'relative',
                height: '100px',
                flexFlow: 'column',
                overflow: 'hidden',
                justifyContent: 'flex-start',
                borderBottom: '1px solid #cdcdcd',
            }}
        >
            <Container
                style={{ position: 'relative', height: '40px', background: 'white', }}
            >
                <Container
                    style={{
                        width: '90%',
                        height: '20px',
                        display: 'block'
                    }}

                >
                    <CardElement onChange={handleChange} onReady={handleReady} style={style} />
                </Container>

            </Container>
            <Container
                style={{
                    position: 'relative',
                    height: '60px',
                    background: 'white',
                    flexFlow: 'column',
                    justifyContent: 'flex-start'
                }}
            >
                <img src='/images/icons/powered_by_stripe.png' style={{ position: 'absolute', right: '10px', height: '24px', objectFit: 'contain' }} />
                <Container
                    onClick={handleComplete}
                    style={{
                        pointerEvents: complete ? 'auto' : 'none',
                        fontFamily: 'Oswald',
                        height: complete ? '60px' : '0px',
                        transition: 'height 250ms ease-out',
                        color: 'white',
                        background: '#00751f'
                    }}
                >
                    COMPLETE PURCHASE
                </Container>
                <Container
                    style={{
                        fontFamily: 'Oswald',
                        overflow: 'hidden',
                        opacity: errorMessage ? 1 : 0,
                        pointerEvents: errorMessage ? 'auto' : 'none',
                        transition: 'opacity 250ms ease-out',
                        color: 'white',
                        background: '#2b2b2b'
                    }}
                >
                    {errorMessage}
                </Container>
            </Container>
            <Container
                onClick={enabled ? () => setStripeActive(true) : null}
                style={{
                    pointerEvents: stripeActive ? 'none' : 'auto',
                    opacity: stripeActive ? 0 : 1,
                    fontFamily: 'Oswald',
                    fontSize: '24px',
                    color: 'white',
                    background: enabled ? '#00751f' : '#efefef',
                    transition: 'all 250ms ease-out'
                }}
            >
                CHECKOUT WITH STRIPE
            </Container>
        </Container>
    )
}

NewCardForm = injectStripe(NewCardForm)

function CheckoutForm({ cart, goBack, goToPayment, resetFields, fields, fieldsComplete, handleChange }) {
    const [total, setTotal] = useState(0)
    
    useEffect(() => {
        let newTotal = 0
        cart.forEach((piece, index) => {
            if (index === 0) {
                newTotal = parseFloat(piece.price)
            }
            else {
                newTotal += parseFloat(piece.price)
            }
        })
        setTotal(newTotal)
    }, [cart])

    const formSections = useMemo(() =>
        Object.keys(fields).map((headerName, index) => {
            switch (headerName) {
                case 'Billing': return (<BillingSection key={`checkout_form_section_${index}`} name={headerName} fields={fields[headerName]} handleChange={handleChange} />)
                default: return (<FormSection key={`checkout_form_section_${index}`} name={headerName} fields={fields[headerName]} handleChange={handleChange} />)
            }
        })
        , [fields])

    const [stripeActive, setStripeActive] = useState(false)

    return (
        <Container
            style={{
                overflowY: 'auto',
                alignItems: 'flex-start'
            }}
        >
            <Container
                style={{
                    height: 'auto',
                    flexFlow: 'column',
                    maxWidth: isMobile ? null : '800px',
                    minHeight: '100%',
                    background: 'white',
                    justifyContent: 'flex-start'
                }}
            >
                <Container
                    style={{
                        position: 'relative',
                        height: headerHeight,
                        fontSize: isMobile ? '24px' : '32px',
                        fontFamily: 'Oswald'
                    }}
                >
                    Checkout
        </Container>
                <Container
                    style={{
                        position: 'relative',
                        height: 'auto',
                        flexFlow: 'column',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Container
                        style={{
                            position: 'relative',
                            height: '60px',
                            fontSize: '18px',
                            background: '#efefef',
                            fontFamily: 'Oswald'
                        }}
                    >
                        {`${cart.length} piece${(cart.length > 1) ? 's' : ''} - $${total}`}
                    </Container>
                    {
                        formSections
                    }
                    {
                        /*
                    <Container
                        onClick={goToPayment}
                        style={{
                            position: 'relative',
                            overflow: 'hidden',
                            height: '80px',
                            fontSize: '18px',
                            color: 'white',
                            background: fieldsComplete ? '#00751f' : '#cdcdcd',
                            pointerEvents: fieldsComplete ? 'auto' : 'none',
                            transition: 'background 250ms ease-out',
                            fontFamily: 'Oswald'
                        }}
                    >
                        CHECKOUT WITH STRIPE
            </Container>
                        */
                    }
                    <Elements>
                        <NewCardForm total={total} resetFields={resetFields} setStripeActive={setStripeActive} enabled={fieldsComplete} stripeActive={stripeActive} goBack={() => setStripeActive(false)} cart={cart} fields={fields} />
                    </Elements>

                </Container>
                <Container
                    onClick={goBack}
                    style={{
                        top: '0px',
                        left: '0px',
                        width: '80px',
                        height: '80px',
                        //opacity: imageActive ? 0 : 1,
                        //pointerEvents: imageActive ? 'none' : 'auto',
                        fontFamily: 'Oswald',
                        fontSize: isMobile ? '36px' : '32px',
                        transition: 'opacity 250ms ease-out'
                    }}
                >
                    {
                        //<img src='/images/icons/downarrow.png' style={{ height: '50px', width: '30px', filter: 'invert(50%)', transform: 'rotate(90deg)' }} alt='save' />
                    }
                    {'<'}
                </Container>
            </Container>
        </Container>

    )
}


function Checkout({ cart, active, goBack, emptyCart }) {
    const [fields, setFields] = useState(JSON.parse(JSON.stringify(formFields)))
    const [stripeActive, setStripeActive] = useState(false)
    const { height, width } = useDocDimensions()


    const handleChange = useCallback((name, index, value) => {
        let newFields = { ...fields }
        newFields[name][index].value = value
        setFields(newFields)
    }, [fields])

    const resetFields = useCallback(() => {
        //console.log('hello')
        setFields(JSON.parse(JSON.stringify(formFields)))
        setStripeActive(false)
        emptyCart()
        goBack()
    })

    const fieldsComplete = useMemo(() => {
        let complete = true
        //console.log(fields)
        Object.keys(fields).forEach(categoryName => {
            const category = fields[categoryName]
            category.forEach(field => {
                if (categoryName !== 'Billing' && field.required && !field.value) {
                    complete = false
                }
            })
        })
        return complete
    }, [fields])

    return (
        <Container
            style={{
                position: 'fixed',
                top: '0',
                zIndex: '2',
                height,
                width,
                alignItems: 'flex-start',
                background: '#cdcdcd',
                opacity: active ? 1 : 0,
                transform: `translateY(${active ? 0 : -50}px)`,
                pointerEvents: active ? 'auto' : 'none',
                transition: 'all 250ms ease-out',
                overflowY: stripeActive ? 'hidden' : 'auto'
            }}
        >

            <StripeProvider apiKey='pk_live_vnftCs3kejVjp9fyK5QEOsvj'>
                <Elements>
                    {/*<CardForm cart={cart} fields={fields} resetFields={resetFields} stripeActive={stripeActive} goBack={() => setStripeActive(false)} />*/}
                    <CheckoutForm resetFields={resetFields} fields={fields} fieldsComplete={fieldsComplete} handleChange={handleChange} setStripeActive={setStripeActive} goToPayment={() => setStripeActive(true)} cart={cart} active={active} goBack={goBack} />
                </Elements>
            </StripeProvider>
        </Container>
    )
}

export default Checkout