import React, { useState, useEffect, useContext, useCallback, useMemo, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import { useDropzone } from 'react-dropzone'

import PopUpsContext from '../../../contexts/PopUpsContext'

import useDocDimensions from '../../../hooks/useDocDimensions'

import isEquivalent from '../../../utils/isEquivalent'

import Container from '../../Container'
import { TextInput, ToggleTextInput, DropdownInput, SquareHoverButton } from '../../Inputs'
import ImageLoader from '../../images/ImageLoader'



function AdminLookbookThumbnail({ previewImage, image, imageLength, deleteImage }) {
    return (
        <Container
            onClick={previewImage}
            style={{
                position: 'relative',
                width: imageLength,
                height: imageLength,
                minWidth: imageLength,
                minHeight: imageLength,
                background: 'white'
            }}
        >
            <ImageLoader alt='' src={image.url} />
            <Container
                onClick={e => {
                    e.stopPropagation()
                    deleteImage()
                }}
                style={{
                    top: '10px',
                    right: '10px',
                    width: '40px',
                    height: '40px',
                    fontSize: '24px',
                    borderRadius: '25px',
                    opacity: '0.7',
                    background: 'white'
                }}
            >
                🗑️
            </Container>
        </Container>
    )
}


function AdminLookbookTab({ lookbookImages, adminLookbookFunctions, setMenuVisible }) {

    const [previewedImage, setPreviewedImage] = useState(null)
    const { showMessage, prompt, setLoading, setLoadingMessage } = useContext(PopUpsContext)
    const { width } = useDocDimensions()
    const imageInput = useRef()

    const imageLength = isMobile ? width / 3 : width / 5

    const handleImagePreview = image => {
        setMenuVisible(false)
        setPreviewedImage(image)
    }

    const handleClosePreview = () => {
        setMenuVisible(true)
        setPreviewedImage(false)
    }

    const deleteImage = (image) => {
        prompt('Delete this?', () => {
            setLoadingMessage('Deleting...')
            setLoading(true)
            adminLookbookFunctions.deleteImage(image, ({ error }) => {
                setLoading(false)
                if (error) {
                    showMessage(error)
                }
            })
        })
    }

    const addImages = images => {
        if (images.length > 1) {
            prompt(`Add ${images.length} images?`, () => {
                setLoadingMessage('Adding images...')
                setLoading(true)
                adminLookbookFunctions.addImages(images, ({ error }) => {
                    setLoading(false)
                    if (error) {
                        showMessage(error)
                    }
                })
            })
        }
        else {
            setLoadingMessage('Adding image...')
            setLoading(true)
            adminLookbookFunctions.addImages(images, ({ error }) => {
                setLoading(false)
                if (error) {
                    showMessage(error)
                }
            })
        }
    }

    const handleAddClick = () => {

    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: addImages })

    return (
        <Container
            style={{
                position: 'relative',
                flexFlow: 'row wrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                fontFamily: 'Oswald',
                height: 'auto'
            }}
        >
            {
                lookbookImages && lookbookImages.map(image =>
                    <AdminLookbookThumbnail imageLength={imageLength} previewImage={() => handleImagePreview(image)} deleteImage={() => deleteImage(image)} image={image} />
                )
            }
            <Container
                {...getRootProps({
                    style: {
                        position: 'relative',
                        height: imageLength,
                        width: imageLength,
                        background: '#cdcdcd',
                        fontSize: '24px',
                        color: 'white'
                    }
                })}

            >
                +
                <input {...getInputProps({ accept: 'image/png' })} />
            </Container>
            <Container
                onClick={handleClosePreview}
                style={{
                    position: 'fixed',
                    pointerEvents: previewedImage ? 'auto' : 'none',
                    opacity: previewedImage ? 1 : 0,
                    background: 'rgba(0,0,0,0.8)',
                    transition: 'opacity 500ms'
                }}
            >
                {previewedImage && <ImageLoader src={previewedImage.url} style={{ height: '100%', objectFit: 'contain' }} alt='preview' />}
            </Container>
        </Container>
    )
}

export default AdminLookbookTab