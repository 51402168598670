import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBiA3-5lZm_vc-UWwfYYbuftgbs5Mtxez8",
    authDomain: "kolton-website.firebaseapp.com",
    databaseURL: "https://kolton-website.firebaseio.com",
    projectId: "kolton-website",
    storageBucket: "kolton-website.appspot.com",
    messagingSenderId: "206756129865",
    appId: "1:206756129865:web:36adff7183092bff"
};
// Initialize Firebase
if (!firebase.apps.length)
    firebase.initializeApp(firebaseConfig);

var storage = firebase.storage();
let db = firebase.firestore()
let metaDb = db.collection('meta')

const getFeatured = callback => {
    metaDb.doc('featured').get().then(res => {
        callback(res.data())
    })
}

const setFeaturedSeason = (seasonName, callback) => {
    metaDb.doc('featured').update({season: seasonName}).then(() => {
        callback(true)
    })
    .catch(err => {
        console.log(err)
        callback(false)
    })
}

export {getFeatured, setFeaturedSeason}
export default {getFeatured, setFeaturedSeason}