import React, { useState, useEffect, useCallback, useRef, useMemo, useContext } from 'react';
import './App.css';

import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/auth'
import { isMobile } from 'react-device-detect'
import Container from './components/Container'

import { PopUpMessage, PopUpConfirmBox, PopUpLoadingScreen } from './components/PopUps'
import { TextInput, DropdownInput, ToggleTextInput, SquareHoverButton } from './components/Inputs';

import useDocDimensions from './hooks/useDocDimensions'

import AdminControlPanel from './components/admin/AdminControlPanel'

import PopUpsContext from './contexts/PopUpsContext'

import isEquivalent from './utils/isEquivalent'
import ImageLoader from './components/images/ImageLoader';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBiA3-5lZm_vc-UWwfYYbuftgbs5Mtxez8",
    authDomain: "kolton-website.firebaseapp.com",
    databaseURL: "https://kolton-website.firebaseio.com",
    projectId: "kolton-website",
    storageBucket: "kolton-website.appspot.com",
    messagingSenderId: "206756129865",
    appId: "1:206756129865:web:36adff7183092bff"
};
// Initialize Firebase
if (!firebase.apps.length)
    firebase.initializeApp(firebaseConfig);

var provider = new firebase.auth.GoogleAuthProvider();

function AdminPage(props) {

    //const [user, setUser] = useState({email: 'millscan@gmail.com'})
    const [user, setUser] = useState(false)

    useEffect(() => {
        if (!user) {
            firebase.auth().getRedirectResult().then(function (result) {
                if (result.credential) {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    //var token = result.credential.accessToken;
                    // ...
                }
                // The signed-in user info.
                var newUser = result.user;
                //console.log(newUser)
                if (!newUser) {
                    firebase.auth().signInWithRedirect(provider);
                }
                else if (newUser.email === 'millscan@gmail.com' || newUser.email === 'koltonvoeltzel@gmail.com' || newUser.email === 'paulinjeti@gmail.com') {
                    setUser(newUser)
                }
                else {
                    setUser('imposter')
                }
                setLoading(false)
            }).catch(function (error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                //var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                //var credential = error.credential;

                console.log(errorCode)
                console.log(errorMessage)
                // ...
            });
        }

    }, [])

    const { showMessage, prompt, setLoading } = useContext(PopUpsContext)

    //const freePieces = useMemo(() => pieces ? pieces.filter(piece => piece.seasonName === '') : null)


    return (
        <Container
            style={{
                alignItems: 'flex-start'
            }}
        >
            {
                user === 'imposter' ?
                    'nice try bro'
                    :

                    user ?
                        <AdminControlPanel user={user} />
                        :
                        null

            }
        </Container>
    )
}

export default AdminPage