import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef, useCallback } from 'react'
import Container from './Container'

const transitionCurve = 'cubic-bezier(0.645, 0.045, 0.355, 1)'

function TextAreaPre(props, ref) {

    
    return (
    <Container
        style={{position: 'relative', alignItems: 'flex-start'}}
    >
        {props.required ? 
            <Container
                style={{
                    right: '0px',
                    width: '20px',
                    top: '0px',
                    height: '20px'
                }}
            >
                <Container
                    style={{
                        width: '5px',
                        height: '5px',
                        borderRadius: '8px',
                        background: props.value ? '#00aa00' : '#aa0000',
                        transition: 'background 100ms ease-out'
                    }}
                />
            </Container>
            :
            null
        }
        <textarea
            ref={ref}
            placeholder={props.placeholder}
            disabled={props.disabled}
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
            style={{
                width: '100%',
                height: '100%',
                fontFamily: 'Source Sans Pro',
                fontSize: '16px',
                padding: '12px 20px',
                display: 'inline-block',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxSizing: 'border-box',
                resize: 'none',
                outline: 'none'
            }}
        />
        </Container>
    )
}

const TextArea = forwardRef(TextAreaPre)

let TextInputPre = (props, ref) => {
    //console.log(props)
    return (
        <Container
            style={{position: 'relative', alignItems: 'flex-start'}}
        >
        <Container
            style={{
                height: '40px'
            }}    
        >
        {props.required ? 
            <Container
                style={{
                    right: '0px',
                    width: '20px',
                    top: '0px',
                    height: '20px'
                }}
            >
                <Container
                    style={{
                        width: '5px',
                        height: '5px',
                        borderRadius: '8px',
                        background: props.value ? '#00aa00' : '#aa0000',
                        transition: 'background 100ms ease-out'
                    }}
                />
            </Container>
            :
            null
        }

        <input
            type='text'
            ref={ref}
            name={props.name}
            placeholder={props.label}
            value={props.value}
            disabled={props.disabled}
            onChange={e => props.onChange(e.target.value)}
            style={{
                outline: 'none',
                fontSize: props.fontSize || '16px',
                width: '100%',
                height: '40px',
                fontFamily: 'Oswald',
                //fontSize: '16px',
                padding: '20px',
                textAlign: 'center',
                display: 'inline-block',
                //borderRadius: '4px',
                boxSizing: 'border-box',
                background: 'none',
                border: 'none',
                borderBottom: '1px solid #efefef',
                ...props.style
            }}
        />
                </Container>
        </Container>
    )
}

const TextInput = forwardRef(TextInputPre)

function PasswordInput(props) {
    //console.log(props)
    return (
        <Container
            style={{position: 'relative', alignItems: 'flex-start'}}
        >
        <Container
            style={{
                height: '40px'
            }}    
        >
        {props.required ? 
            <Container
                style={{
                    right: '0px',
                    width: '20px',
                    top: '0px',
                    height: '20px'
                }}
            >
                <Container
                    style={{
                        width: '5px',
                        height: '5px',
                        borderRadius: '8px',
                        background: props.value ? '#00aa00' : '#aa0000',
                        transition: 'background 100ms ease-out'
                    }}
                />
            </Container>
            :
            null
        }

        <input
            type='password'
            placeholder={props.placeholder}
            value={props.value}
            disabled={props.disabled}
            onChange={e => props.onChange(e.target.value)}
            style={{
                outline: 'none',
                width: '100%',
                height: '40px',
                fontFamily: 'Source Sans Pro',
                fontSize: '16px',
                padding: '12px 20px',
                display: 'inline-block',
                borderRadius: '4px',
                boxSizing: 'border-box',
                border: '1px solid #ccc',
                ...props.style
            }}
        />
                </Container>
        </Container>
    )
}


function SquareHoverButton({width, onClick, children}){
    const [hovered, setHovered] = useState(false)

    return(
        <Container
        onClick={onClick}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={{
            position: 'relative',
            width: width,
            height: width,
            minWidth: width,
            minHeight: width,
            background: hovered ? '#777' : '#ababab',
            color: 'white',
            fontSize: '72px',
            transition: 'background 250ms ease-out'
        }}
    >

        <Container style={{ width: '80%', color: '#efefef', fontSize: '32px', transition: `opacity 250ms ease-out`, pointerEvents: 'none' }}>
            {children}
        </Container>
    </Container>
    )
}

function DropdownInput(props) {
    const [currentSuggestion, setCurrentSuggestion] = useState('')
    const [toggleDropdown, setToggleDropdown] = useState(false)
    const [dropdownHover, setDropdownHover] = useState(false)
    const [hoveredOption, setHoveredOption] = useState(null)

    //set autofill on enter
    const inputRef = useRef()

    const handleChangeValue = useCallback((value) => {
        //console.log(value)
        props.onChange(value)
        if (value === '') {
            setCurrentSuggestion('')
            return
        }

        for (let i = 0; i < props.options.length; i++) {
            let option = props.options[i]
            if (option.indexOf(value) === 0) {
                //console.log(option)
                setCurrentSuggestion(option)
                return
            }
        }
        setCurrentSuggestion('')
    }, [props, props.options])

    const handleSelectOption = (option) => {
        if(!props.addItem){
            setToggleDropdown(false)
            setCurrentSuggestion('')
            props.onChange(option)
        }

    }

    useEffect(() => {
        const handler = (e) => {
            if (inputRef.current === document.activeElement) {
                if (e.keyCode === 13) {
                    //e.preventDefault()
                    //console.log(currentSuggestion)
                    if(props.addItem){
                        props.addItem()
                    }
                    else{
                        if (currentSuggestion !== '') {
                            handleChangeValue(currentSuggestion)
                        }
    
                        if (props.onComplete) props.onComplete()
                    }
                }
                if (e.keyCode === 9) {
                    if (currentSuggestion !== '') {
                        handleChangeValue(currentSuggestion)
                    }
                    if (props.onComplete) props.onComplete()
                }
            }
        }
        window.addEventListener('keydown', handler)

        return () => {
            window.removeEventListener('keydown', handler)
        }
    }, [currentSuggestion, handleChangeValue, props])

    //console.log(currentSuggestion)

    return (
        <Container
            style={{
                flexFlow: 'column',
                height: 'auto',
                fontFamily: 'Oswald',
                minHeight: '40px',
                justifyContent: 'flex-start',
                zIndex: props.top ? '2' : '1',
                overflow: 'hidden',
                borderBottom: '1px solid #ccc',
                borderRadius: '4px',
                background: props.transparent ? 'rgba(0,0,0,0)' : 'white'
            }}
        >
            <Container
                style={{ height: '40px', position: 'relative' }}>
                <input
                    disabled
                    type='text'
                    placeholder={currentSuggestion}
                    style={{
                        position: 'absolute',
                        top: '0px',
                        right: '0px',
                        outline: 'none',
                        width: '100%',
                        height: '40px',
                        fontFamily: 'Oswald',
                        fontSize: '20px',
                        padding: '12px 20px',
                        display: 'inline-block',
                        boxSizing: 'border-box',
                        color: props.color || null,
                        border: 'none',
                        zIndex: '0',
                        background: 'rgba(0,0,0,0)'
                    }}
                />
                <input
                    name={props.name}
                    disabled={props.disabled || props.notype}
                    type='text'
                    ref={inputRef}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={e => handleChangeValue(e.target.value)}
                    style={{
                        outline: 'none',
                        width: '100%',
                        height: '40px',
                        fontFamily: 'Oswald',
                        color: '#444',
                        fontSize: '20px',
                        padding: '12px 20px',
                        display: 'inline-block',
                        color: props.color || null,
                        boxSizing: 'border-box',
                        background: 'none',
                        border: 'none',
                        
                        zIndex: '1'
                    }}
                />
                <Container
                    onMouseEnter={() => setDropdownHover(true)}
                    onMouseLeave={() => setDropdownHover(false)}
                    onClick={() => setToggleDropdown(!toggleDropdown)}
                    style={{
                        right: '5px',
                        top: '0px',
                        height: '40px',
                        width: '40px',
                        cursor: 'pointer',
                        color: dropdownHover ? '#4c4c4c' : '#777',
                        zIndex: '2'
                    }}
                >
                    ▼
                </Container>
            </Container>
            <Container
                style={{
                    position: 'relative',
                    height: toggleDropdown ? (props.extend ? `${props.options.length * 40}px` : `${Math.min(props.options.length * 40, 40*3)}px`)  : '0px',
                    transition: `all 250ms ${transitionCurve}`,
                    background: 'white',
                    borderBottom: '1px solid #efefef',
                    overflow: 'hidden',
                    overflowY: 'auto',
                    flexFlow: 'column',
                    justifyContent: 'flex-start'
                }}
            >
                {
                    props.options.map((option, index) =>
                        <Container
                            key={`dropdown_suggestion_${option}`}
                            onMouseEnter={() => setHoveredOption(option)}
                            onMouseLeave={() => setHoveredOption(null)}
                            onClick={() => handleSelectOption(option)}
                            style={{
                                position: 'relative',
                                height: '40px',
                                minHeight: '40px',
                                cursor: 'pointer',
                                justifyContent: 'flex-start',
                                background: hoveredOption === option ? 'rgba(0, 0, 0, 0.05)' : 'rgba(0,0,0,0)',
                                color: hoveredOption === option ? '#000000' : '#444'
                            }} >
                                <Container
                                    style={{
                                        left: '20px',
                                        right: props.removeItem ? '40px' : '20px',
                                        width: 'auto',
                                        fontSize: '14px',
                                        textOverflow: 'ellipsis',
                                        justifyContent: 'flex-start'
                                    }}
                                >
                                {option}
                                </Container>
                            {
                                props.removeItem ? 
                                <Container 
                                onClick={e => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    props.removeItem(option, index)
                                }}
                                style={{
                                    right: '0px',
                                    top: '0px',
                                    height: '40px',
                                    width: '40px',
                                    cursor: 'pointer',
                                    color: '#777',
                                    zIndex: '2'
                                }}
                                >
                                    <img src='/images/admin/icons/cancel.svg' style={{height: '40%', objectFit: 'contain', opacity: '0.5'}} alt='delete'/>
                                </Container>
                                :
                                null
                            }
                        </Container>
                    )
                }
            </Container>
            {props.required ? 
            <Container
                style={{
                    right: '0px',
                    width: '20px',
                    top: '0px',
                    height: '20px'
                }}
            >
                <Container
                    style={{
                        width: '5px',
                        height: '5px',
                        borderRadius: '8px',
                        background: props.value ? '#00aa00' : '#aa0000',
                        transition: 'background 100ms ease-out'
                    }}
                />
            </Container>
            :
            null
        }
        </Container>
    )
}

let DatePicker = (props, ref) => {

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        reset: () => {
            inputRef.current.value = ''
            inputRef.current.type = 'file'
            //console.log('reset')
        }
    }))

    const handleChange = e => props.onChange(e)

    return(
        <Container>
            <Container
                style={{
                    height: '100px',
                    width: 'auto',
                }}
            >
                <input value={props.value} onChange={handleChange} ref={inputRef} type='date'/>
            </Container>
        </Container>
    )
}

DatePicker = forwardRef(DatePicker)

let ImageInput = (props, ref) => {
    //console.log(props)
    const [tempCaption, setTempCaption] = useState(props.image ? props.image.caption || '' : '')

    const [uploadHovered, setUploadHovered] = useState(false)
    const [removeHovered, setRemoveHovered] = useState(false)
    const [replaceHovered, setReplaceHovered] = useState(false)
    const [captionHovered, setCaptionHovered] = useState(false)
    const [toggleCaptionEdit, setToggleCaptionEdit] = useState(false)

    const inputRef = useRef()
    useImperativeHandle(ref, () => ({
        reset: () => {
            inputRef.current.value = ''
            inputRef.current.type = 'file'
            //console.log('reset')
        },
        open: () => {
            inputRef.current.click()
        }
    }))

    const handleImageChanged = e => {
        //console.log(e.target.files)
        if (e.target.files.length !== 0) {
            var reader = new FileReader()
            reader.onload = (loadEvent) => {
                //setDataURL(loadEvent.target.result)
                props.editImage({ ...props.image, dataURL: loadEvent.target.result })
                //console.log(loadEvent.target.result)
            }
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const resetCaption = () => {
        setTempCaption(props.image ? props.image.caption || '' : '')
        setToggleCaptionEdit(false)
    }

    const handleCaptionChange = (value) => {
        setTempCaption(value)
    }

    const handleConfirmCaption = () => {
        props.editImage({ ...props.image, caption: tempCaption })
        setToggleCaptionEdit(false)
    }

    const handleClick = (e) => {
        //console.log(e.target)

    }

    const handleRemove = (e) => {
        if (props.onRemove) props.onRemove(selectedImage)
        inputRef.current.files = null
        setTempCaption(props.image ? props.image.caption || '' : '')
        setToggleCaptionEdit(false)
        e.preventDefault()
    }
    const selectedImage = props.image.dataURL || props.image.url
    return (
        <Container
            style={{
                width: '100%',
                height: '100px',
                borderRadius: '5px',
                cursor: 'pointer',
                overflow: 'hidden',
                background: '#dedede'
            }}
        >
            <Container
                style={{
                    flexFlow: 'column',
                    justifyContent: 'flex-start'
                }}
            >
                <Container
                    style={{
                        pointerEvents: 'none',
                    }}
                >
                    {selectedImage ?
                        <img alt='' src={selectedImage} style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            //opacity: removeHovered || replaceHovered ? 0.2 : 1
                        }} />
                        : props.initImage ?
                            <img alt='' src={props.initImage.url} style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                //opacity: removeHovered || replaceHovered ? 0.2 : 1
                            }} />
                            :
                            null
                    }

                </Container>
                <Container
                    style={{
                        position: 'relative',
                        height: (selectedImage && !props.noCaption) ? '70px' : '100px',
                    }}
                >
                    <label
                        onClick={handleClick}
                        style={{
                            position: 'abolute',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <input
                            type='file'
                            accept={props.png ? 'image/png' : 'image/*'}
                            ref={inputRef}
                            onChange={handleImageChanged}
                            style={{
                                width: '0.1px',
                                height: '0.1px',
                                opacity: 0,
                                overflow: 'hidden',
                                position: 'absolute',
                                zIndex: '-1'
                            }}
                        />
                        <Container>
                            {
                                selectedImage || props.initImage ?
                                    <Container>
                                        <Container
                                            onMouseEnter={() => setReplaceHovered(true)}
                                            onMouseLeave={() => setReplaceHovered(false)}
                                            style={{
                                                position: 'relative',
                                                pointerEvents: selectedImage || props.initImage ? 'auto' : 'none',
                                                width: props.required ? '100%' : '50%',
                                                opacity: replaceHovered || captionHovered ? 1 : 0,
                                                background:'rgba(255,255,255,0.8)',
                                                transition: `all 250ms ${transitionCurve}`,
                                                fontFamily: 'Source Sans Pro',
                                                fontWeight: '600',
                                                fontSize: '16px'
                                            }}
                                        >
                                            <img src='/images/admin/icons/edit.svg' alt='edit' title='Change' style={{height: '20px', objectFit: 'contain'}}/>
                                            
                                </Container>
                                {
                                    !props.required &&
                                    <Container
                                    onMouseEnter={() => setRemoveHovered(true)}
                                    onMouseLeave={() => setRemoveHovered(false)}
                                    onClick={handleRemove}
                                    style={{
                                        pointerEvents: selectedImage || props.initImage ? 'auto' : 'none',
                                        position: 'relative',
                                        width: '50%',
                                        color: '#cd0000',
                                        opacity: removeHovered || captionHovered ? 1 : 0,
                                        background:'rgba(255,255,255,0.8)',
                                        transition: `all 250ms ${transitionCurve}`,
                                        fontSize: '18px'
                                    }}
                                >
                                    <img src='/images/admin/icons/cancel.svg' alt='delete' title='Remove' style={{height: '20px', objectFit: 'contain'}}/>
                                    
                        </Container>
                                }

                                    </Container>
                                    :
                                    <Container
                                        onMouseEnter={() => setUploadHovered(true)}
                                        onMouseLeave={() => setUploadHovered(false)}
                                        style={{
                                            background: uploadHovered ? '#cdcdcd' : '#efefef'
                                        }}
                                    >
                                        {props.children || '+ Add Image'}
                                    </Container>
                            }
                        </Container>
                    </label>
                </Container>
                {
                    !props.noCaption &&
                    <Container
                    onMouseEnter={() => setCaptionHovered(true)}
                    onMouseLeave={() => setCaptionHovered(false)}
                    onClick={() => setToggleCaptionEdit(true)}
                    style={{
                        position: 'relative',
                        height: selectedImage || props.initImage ? '30px' : '0px',
                        overflow: 'hidden',
                        opacity: removeHovered || replaceHovered || captionHovered ? 1 : 0,
                        background: 'rgba(255,255,255,0.8)' ,
                        transition: `all 250ms ${transitionCurve}`,
                        fontSize: '16px'
                    }}
                >
                    <SimpleHoverButton>+ ADD CAPTION</SimpleHoverButton>
                    </Container>
                }
            </Container>
            <Container
                style={{
                    position: 'fixed',
                    top: '0px',
                    bottom: '0px',
                    left: '0px',
                    right: '0px',
                    zIndex: '3',
                    height: '100vh',
                    width: '100vw',
                    background: 'rgba(0,0,0,0.7)',
                    opacity: toggleCaptionEdit ? 1 : 0,
                    pointerEvents: toggleCaptionEdit ? 'auto' : 'none',
                    transition: `opacity 250ms ${transitionCurve}`
                }}
            >
                <Container
                    style={{
                        width: '600px',
                        height: '600px',
                        flexFlow: 'column',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Container
                        style={{ width: '600px', height: '310px', position: 'relative', alignItems: 'space-between' }}
                    >
                        <Container
                            style={{ position: 'relative', height: '250px' }}
                        >
                            <TextArea value={tempCaption} onChange={handleCaptionChange} placeholder='Image Caption' />
                        </Container>
                        <Container style={{ width: '600px', height: '60px', bottom: '0px' }}>
                            <Container style={{ width: '50%', height: '90%', position: 'relative' }}>
                                <HoverButton
                                    onClick={resetCaption}
                                >
                                    CANCEL
                            </HoverButton>
                            </Container>
                            <Container style={{ width: '50%', height: '90%', position: 'relative' }}>
                                <HoverButton
                                    onClick={handleConfirmCaption}
                                >
                                    CONFIRM
                            </HoverButton>
                            </Container>
                        </Container>
                    </Container>
                </Container>

            </Container>
        </Container>
    )
}

ImageInput = forwardRef(ImageInput)

function ImageHoverButton(props) {
    return (
        <Container
            onMouseEnter={() => props.setHovered(true)}
            onMouseLeave={() => props.setHovered(false)}
            onClick={props.onClick}
            style={{
                width: props.sideLength || '40px',
                height: props.sideLength || '40px',
                borderRadius: `${props.sideLength * 2 || 20}px`
            }}
        >
            <img src={props.src} alt={props.title} title={props.title} 
                style={{ 
                    height: '80%', 
                objectFit: 'contain', 
                filter: props.hovered ? `invert(.5) sepia(1) saturate(5) hue-rotate(${props.hueRotate || -50}deg)` : 'none',
            }} 
            />
        </Container>
    )
}

function FileInput(props, ref) {
    //console.log(props)
    const [tempDescription, setTempDescription] = useState(props.file ? props.file.description || '' : '')

    const [uploadHovered, setUploadHovered] = useState(false)
    const [removeHovered, setRemoveHovered] = useState(false)
    const [replaceHovered, setReplaceHovered] = useState(false)

    const [descriptionHovered, setDescriptionHovered] = useState(false)
    const [toggleDescriptionEdit, setToggleDescriptionEdit] = useState(false)

    const inputRef = useRef()

    useImperativeHandle(ref, () => ({
        reset: () => {
            inputRef.current.value = ''
            inputRef.current.type = 'file'
            //console.log('reset')
        },
        click: () => {
            inputRef.current.click()
        }
    }))

    const handleFileChanged = e => {
        console.log(e.target.files)
        if (e.target.files.length !== 0) {
            var reader = new FileReader()
            let newName = e.target.files[0].name
            reader.onload = (loadEvent) => {
                //setDataURL(loadEvent.target.result)
                props.editFile({ ...props.file, dataURL: loadEvent.target.result, name: newName.replace(/\.[^/.]+$/, "") })
                //console.log(loadEvent.target.result)
            }
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const resetDescription = () => {
        setTempDescription(props.file ? props.file.description || '' : '')
        setToggleDescriptionEdit(false)
    }

    const handleNameChange = value => {
        console.log(value)
        props.editFile({ ...props.file, name: value })
    }

    const handleDescriptionChange = value => {
        setTempDescription(value)
    }

    const handleConfirmDescription = () => {
        props.editFile({ ...props.file, description: tempDescription })
        setToggleDescriptionEdit(false)
    }

    const handleUploadFile = (e) => {
    }

    const handleReplaceFile = (e) => {
        console.log(inputRef.current.disabled)
        inputRef.current.disabled = false
        setTimeout(() => inputRef.current.disabled = true, 10)
        inputRef.current.disabled = true
    }

    const handleRemove = (e) => {
        if (props.onRemove) props.onRemove()
        inputRef.current.files = null
        setTempDescription(props.file ? props.file.description || '' : '')
        e.preventDefault()
    }

    return (
        <Container
            style={{
                width: '100%',
                height: '80px',
                borderRadius: '5px',
                cursor: 'pointer',
                overflow: 'hidden',
                background: '#dedede'
            }}
        >
            <Container
                style={{
                    flexFlow: 'column',
                    justifyContent: 'flex-start'
                }}
            >
                <Container
                    style={{
                        position: 'relative',
                        height: '80px'
                    }}
                >
                    <label
                        onClick={handleUploadFile}
                        style={{
                            position: 'abolute',
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <input
                            type='file'
                            accept={props.accept}
                            ref={inputRef}
                            onChange={handleFileChanged}
                            style={{
                                width: '100%',
                                height: '100%',
                                width: '0.1px',
                                height: '0.1px',
                                opacity: 0,
                                overflow: 'hidden',
                                position: 'absolute',
                                zIndex: '-1'
                            }}
                        />
                        <Container>
                            {
                                props.file ?
                                    <Container
                                        style={{
                                            height: '80px',
                                            position: 'relative',
                                            flexFlow: 'column',
                                            overflow: 'hidden'
                                        }}
                                    >
                                        <Container
                                            onClick={e => e.preventDefault()}
                                            style={{
                                                height: '50px',
                                                left: '0px',
                                                top: '0px',
                                                right: '90px',
                                                width: null,
                                                transition: `all 250ms ${transitionCurve}`,
                                                fontSize: '18px'
                                            }}
                                        >
                                            <Container
                                                style={{
                                                    height: '90%',
                                                    width: '90%',
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                <TextInput style={{ padding: '12px 40px 12px 20px' }} value={props.file.name} onChange={handleNameChange} />
                                                <Container
                                                    style={{
                                                        color: '#2b2b2b',
                                                        fontSize: '16px',
                                                        right: '10px',
                                                        top: '2px',
                                                        height: '36px',
                                                        background: 'white',
                                                        pointerEvents: 'none',
                                                        width: 'auto'
                                                    }}
                                                >
                                                    {props.accept}
                                                </Container>
                                            </Container>
                                        </Container>
                                        <Container
                                            onMouseEnter={() => setReplaceHovered(true)}
                                            onMouseLeave={() => setReplaceHovered(false)}

                                            style={{
                                                top: '0px',
                                                right: '55px',
                                                pointerEvents: props.file ? 'auto' : 'none',
                                                width: '50px',
                                                height: '50px',
                                                fontSize: '18px'
                                            }}
                                        >
                                            <ImageHoverButton 
                                                src='/images/admin/icons/upload.svg' 
                                                hovered={replaceHovered} 
                                                setHovered={setReplaceHovered}
                                            />
                                        </Container>
                                        <Container
                                            onClick={handleRemove}
                                            style={{
                                                pointerEvents: props.file ? 'auto' : 'none',
                                                width: '50px',
                                                height: '50px',
                                                top: '0px',
                                                paddingRight: '10px',
                                                right: '0px',
                                                color: '#cd0000',
                                                fontSize: '18px'
                                            }}
                                        >
                                            <ImageHoverButton 
                                                hoverColor='rgba(255,0,0,0.5)' 
                                                idleColor='rgba(0,0,0,0' 
                                                src='/images/admin/icons/cancel.svg' 
                                                title='delete' 
                                                hovered={removeHovered} 
                                                setHovered={setRemoveHovered}
                                            />
                                        </Container>
                                        <Container
                                            onMouseEnter={() => setDescriptionHovered(true)}
                                            onMouseLeave={() => setDescriptionHovered(false)}
                                            onClick={(e) => { e.preventDefault(); setToggleDescriptionEdit(true) }}
                                            style={{
                                                bottom: '0px',
                                                height: '30px',
                                                width: '90%',
                                                overflow: 'hidden',
                                                padding: '0px 20px',
                                                whiteSpace: 'nowrap',
                                                display: 'block',
                                                textOverflow: 'ellipsis',
                                                justifyContent: 'flex-start',
                                                opacity: descriptionHovered ? 0 : 1,
                                                transition: `all 250ms ${transitionCurve}`,
                                                fontSize: '16px'
                                            }}
                                        >
                                            { props.file.description || '+ ADD DESCRIPTION'}
                                        </Container>

                                        <Container
                                            onMouseEnter={() => setDescriptionHovered(true)}
                                            onMouseLeave={() => setDescriptionHovered(false)}
                                            onClick={(e) => { e.preventDefault(); setToggleDescriptionEdit(true) }}
                                            style={{
                                                bottom: '0px',
                                                height: '30px',
                                                overflow: 'hidden',
                                                display: 'block',
                                                width: '90%',
                                                opacity: descriptionHovered ? 1 : 0,
                                                color: 'white',
                                                transition: `all 250ms ${transitionCurve}`,
                                                fontSize: '16px'
                                            }}
                                        >
                                            {props.file ? props.file.description ? '+ EDIT DESCRIPTION' : '+ ADD DESCRIPTION' : "+ ADD DESCRIPTION"}
                                        </Container>
                                    </Container>
                                    :
                                    <Container
                                        onMouseEnter={() => setUploadHovered(true)}
                                        onMouseLeave={() => setUploadHovered(false)}
                                        style={{
                                            background: uploadHovered ? '#cdcdcd' : '#efefef'
                                        }}
                                    >
                                        {props.children}
                                    </Container>
                            }
                        </Container>
                    </label>
                </Container>
            </Container>
            <Container
                style={{
                    position: 'fixed',
                    top: '0px',
                    bottom: '0px',
                    left: '0px',
                    right: '0px',
                    zIndex: '3',
                    height: '100vh',
                    width: '100vw',
                    background: 'rgba(0,0,0,0.7)',
                    opacity: toggleDescriptionEdit ? 1 : 0,
                    pointerEvents: toggleDescriptionEdit ? 'auto' : 'none',
                    transition: `opacity 250ms ${transitionCurve}`
                }}
            >
                <Container
                    style={{
                        width: '600px',
                        height: '600px',
                        flexFlow: 'column',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Container
                        style={{ width: '600px', height: '310px', position: 'relative', alignItems: 'space-between' }}
                    >
                        <Container
                            style={{ position: 'relative', height: '250px' }}
                        >
                            <TextArea value={tempDescription} onChange={handleDescriptionChange} placeholder='File description' />
                        </Container>
                        <Container style={{ width: '600px', height: '60px', bottom: '0px' }}>
                            <Container style={{ width: '50%', height: '90%', position: 'relative' }}>
                                <HoverButton
                                    onClick={resetDescription}
                                >
                                    CANCEL
                            </HoverButton>
                            </Container>
                            <Container style={{ width: '50%', height: '90%', position: 'relative' }}>
                                <HoverButton
                                    onClick={handleConfirmDescription}
                                >
                                    CONFIRM
                            </HoverButton>
                            </Container>
                        </Container>
                    </Container>
                </Container>

            </Container>
        </Container>
    )
}

FileInput = forwardRef(FileInput)

function HoverButton(props) {
    const [hovered, setHovered] = useState(false)
    const idleColor = props.idleColor || '#dedede'
    const hoverColor = props.hoverColor || '#cdcdcd'
    return (
        <Container
            onClick={props.onClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                width: '90%',
                height: '90%',
                background: hovered ?  hoverColor : idleColor,
                borderRadius: '5px',
                cursor: 'pointer',
                transition: `background 250ms ${transitionCurve}`,
                ...props.style
            }}
        >
            {props.children}
        </Container>
    )
}

function SimpleHoverButton(props) {
    const [hovered, setHovered] = useState(false)
    const hoverColor = props.hoverColor || '#2b2b2b'
    const idleColor = props.idleColor || '#676767'

    return (
        <Container
            onClick={props.onClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            style={{
                color: hovered ? hoverColor : idleColor,
                cursor: 'pointer',
                ...props.style
            }}
        >
            {props.children}
        </Container>
    )
}

function ToggleTextInput(props, ref) {

    const [hovered, setHovered] = useState(false)
    const [changedValue, setChangedValue] = useState(null)
  
    const inputRef = useRef()
  
    useImperativeHandle(ref, () => ({
      focus: () => {
        setChangedValue(props.value)
      },
      blur: () => {
        handleEditClick()
        setChangedValue(null)
      }
    }))
    //console.log(props)
  
    useEffect(() => {
      if (!props.value) {
        setChangedValue('')
      }
    }, [props.value])
  
    const handleEditClick = useCallback((e) => {
  
      //e.stopPropagation()
      if (changedValue !== null) {
        if (changedValue === '') {
          if (!props.required) {
            props.editValue(props.value, null)
          }
          else{
              props.editValue(props.value, props.value)
          }
          setChangedValue(null)
        }
        else if (changedValue === props.value) {
          setChangedValue(null)
        }
        else {
          props.editValue(props.value, changedValue)
          setChangedValue(null)
        }
      }
      else {
        setChangedValue(props.value || ' ')
      }
  
    }, [props, changedValue])
  
    const handleChangeValue = (value) => {
      //console.log(changedValue)
      setChangedValue(value)
    }
  
  
    useEffect(() => {
      const handlePaste = (e) => {
        //e.preventDefault()
        //e.stopPropagation()
        const pastedText = (e.clipboardData || window.clipboardData).getData('text')
  
        const splitText = pastedText.split('\n')
  
        if(splitText.length > 1){
          //console.log(changedValue)
          handleEditClick()
          props.onPaste(e)
        }
      }
  
      if (props.onPaste && inputRef.current) {
        inputRef.current.addEventListener('paste', handlePaste)
      }
    }, [props, changedValue, handleEditClick])
  
    useEffect(() => {
      const handleKeyPress = e => {
        let { keyCode } = e
        if (inputRef.current) {
          if (inputRef.current === document.activeElement) {
            //console.log(keyCode)
            if (keyCode === 13) {
              handleEditClick()
            }
          }
        }
      }
  
      window.addEventListener('keydown', handleKeyPress)
      return () => window.removeEventListener('keydown', handleKeyPress)
    }, [handleEditClick, changedValue])
  
    useEffect(() => {
      const handleBlur = e => {
        handleEditClick()
      }
      if(inputRef.current){
        inputRef.current.addEventListener('blur', handleBlur)
      }
  
    }, [])
  
    return (
      <Container>
        <Container
          style={{
            height: '40px',
            //alignItems: 'flex-start',
          }}
        >
          <Container
            style={{
              left: '0px',
              right: '0px',
              width: null
            }}
          >
            {(changedValue !== null) || props.value === '' ?
              <Container
                onClick={e => { e.preventDefault(); e.stopPropagation() }}
              >
                <TextInput fontSize={props.fontSize || '20px'} ref={inputRef} value={changedValue || ''} onChange={handleChangeValue} placeholder={props.valueTitle || props.placeholder || 'New Value'} />
              </Container>
              :
              <Container
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={handleEditClick}
                title='edit'
                style={{
                  //justifyContent: 'flex-start',
                  cursor: 'pointer',
                  padding: '20px',
                  fontSize: props.fontSize || '20px',
                  color: hovered ? 'black' : (props.value ? '#444' : '#777')
                }}
              >
                {
                  props.header ?
                    <h4 style={{ margin: '5px 0px' }}>
                      {props.value || props.placeholder}
                    </h4>
                    :
                    props.value || props.placeholder
                }
  
              </Container>
  
            }
  
          </Container>
          <Container
            style={{ right: '10px', width: '20px' }}
          >
            {
              (changedValue || !props.value) &&
              <img
                onClick={
                  e => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleEditClick(e)
                  }
                }
                src='/images/icons/edit.png'
                alt='edit'
                style={{ height: '16px', objectFit: 'contain' }}
              />
            }
  
          </Container>
  
        </Container>
      </Container>
    )
  }
  
  ToggleTextInput = forwardRef(ToggleTextInput)
  
  function ToggleTextArea(props, ref) {
  
    const inputRef = useRef()
    const [hovered, setHovered] = useState(false)
    const [changedValue, setChangedValue] = useState(null)
  
    //console.log(ref)
  
    useImperativeHandle(ref, () => ({
      focus: () => {
        setChangedValue(props.value)
      },
      blur: () => {
        handleEditClick()
        setChangedValue(null)
      }
    }))
    //console.log(props)
  
    const handleEditClick = useCallback((e) => {
      if (changedValue !== null) {
        if (changedValue === '') {
            props.editValue(props.value, '')
          setChangedValue(null)
        }
        else if (changedValue === props.value) {
          setChangedValue(null)
        }
        else {
          props.editValue(props.value, changedValue)
          setChangedValue(null)
        }
      }
      else {
        setChangedValue(props.value)
      }
  
    }, [props, changedValue])
  
    const handleChangeValue = (value) => {
      setChangedValue(value)
    }
  
    useEffect(() => {
      const handleBlur = e => {
        handleEditClick()
      }
      if(inputRef.current){
        inputRef.current.addEventListener('blur', handleBlur)
      }
  
    })
  
    return (
      <Container>
        <Container
          style={{
            height: 'auto',
            minHeight: props.height || '50px'
            //alignItems: 'flex-start',
          }}
        >
          <Container
            style={{
              left: '0px',
              right: '0px',
              height: 'auto',
              minHeight: props.height || '50px',
              width: null
            }}
          >
            {(changedValue !== null) || props.value === '' ?
              <Container
                onClick={e => { e.preventDefault(); e.stopPropagation() }}
              >
                <TextArea ref={inputRef} value={changedValue || ''} onChange={handleChangeValue} placeholder='New Value' />
              </Container>
              :
              <Container
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                onClick={handleEditClick}
                title='edit'
                style={{
                  //justifyContent: 'flex-start',
                  cursor: 'pointer',
                  width: '90%',
                  height: '80%',
                  padding: '10px 20px',
                  alignItems: 'flex-start',
                  color: hovered ? 'black' : '#444',
                  border: '1px solid #efefef',
                  borderRadius: '4px',
                  whiteSpace:'pre-wrap',
                  overflowY: 'auto'
                }}
              >
                {
                  props.value
                }
  
              </Container>
  
            }
  
          </Container>
          <Container
            style={{ right: '10px', top: '10px', width: '20px', height: '20px' }}
          >
  
            {
              (changedValue || changedValue === '') && <img onClick={handleEditClick} src='/images/icons/accept.png' alt='edit' style={{ height: '16px', objectFit: 'contain' }} />
            }
  
          </Container>
  
        </Container>
      </Container>
    )
  }
  
  ToggleTextArea = forwardRef(ToggleTextArea)

export { TextInput, ImageInput, FileInput, DropdownInput, TextArea, HoverButton, SimpleHoverButton, ImageHoverButton, PasswordInput,
        ToggleTextInput, ToggleTextArea, DatePicker, SquareHoverButton }