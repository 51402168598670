import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from 'react';
import './App.css';
import firebase from 'firebase/app'
import 'firebase/storage'
import { isMobile } from 'react-device-detect'

import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom'
import AdminPage from './AdminPage'
import Container from './components/Container'
import PageRouter from './components/routing/PageRouter'

import { getFeatured } from './utils/metaUtils'
import { getPieces } from './utils/pieceUtils';
import useDocDimensions from './hooks/useDocDimensions';
import { PopUpMessage, PopUpLoadingScreen, PopUpConfirmBox } from './components/PopUps'


import Checkout from './components/forms/Checkout'
import PopUpsContext from './contexts/PopUpsContext'
import PiecesContext from './contexts/PiecesContext';

import Lookbook from './pages/Lookbook'
import Shop from './pages/Shop'
import Archive from './pages/Archive'

import useLookbook from './hooks/admin/useLookbook'
import SlidingMenuRouter from './components/routing/SlidingMenuRouter';

const headerHeight = isMobile ? 80 : 100


let imagesRef = firebase.storage().ref('images')

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBiA3-5lZm_vc-UWwfYYbuftgbs5Mtxez8",
  authDomain: "kolton-website.firebaseapp.com",
  databaseURL: "https://kolton-website.firebaseio.com",
  projectId: "kolton-website",
  storageBucket: "kolton-website.appspot.com",
  messagingSenderId: "206756129865",
  appId: "1:206756129865:web:36adff7183092bff"
};
// Initialize Firebase
// Initialize Firebase
if (!firebase.apps.length)
  firebase.initializeApp(firebaseConfig);

function ShoppingCart({ cart, removeFromCart, setCheckoutActive }) {

  const { width } = useDocDimensions()
  console.log(cart)
  return (
    <Container
      style={{
        alignItems: cart.length ? 'flex-start' : 'center',
        fontFamily: 'Oswald',
        overflowX: 'hidden',
        overflowY: 'auto'
      }}
    >
      {
        cart.length ?
          <Container
            style={{
              flexFlow: 'row wrap',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              height: 'auto'
            }}
          >
            {
              cart.map((piece, index) =>
                <Container
                  key={`cart_item_${index}`}
                  style={{
                    position: 'relative',
                    height: '150px',
                    borderBottom: '1px solid #efefef'
                  }}
                >
                  <Container
                    style={{
                      left: '0px',
                      height: '150px',
                      width: '150px'
                    }}
                  >
                    <img src={piece.image} alt={piece.name} style={{ height: '80%', maxWidth: '80%', objectFit: 'contain' }} />
                  </Container>
                  <Container
                    style={{
                      left: '160px',
                      height: '150px',
                      width: width - 140,
                      flexFlow: 'column',
                      fontFamily: 'Oswald',
                    }}
                  >
                    <Container
                      style={{
                        position: 'relative',
                        height: '40px',
                        fontSize: '18px',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {piece.name}
                    </Container>
                    <Container
                      style={{
                        position: 'relative',
                        height: '30px',
                        fontSize: '16px',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {`$${piece.price}`}
                    </Container>
                  </Container>
                  <Container
                    onClick={() => removeFromCart(piece)}
                    style={{
                      top: '0px',
                      right: '0px',
                      height: 'auto',
                      width: 'auto',
                      padding: '15px'
                    }}
                  >
                    - REMOVE
                  </Container>
                </Container>
              )
            }
            <Container
              onClick={() => setCheckoutActive(true)}
              style={{
                position: 'relative',
                height: '60px',
                fontSize: '18px',
                background: '#efefef',
                fontFamily: 'Oswald'
              }}
            >
              CHECKOUT
            </Container>
          </Container>
          :
          'go buy some shit'
      }
    </Container>
  )
}

function CheckoutPage({ cart, removeFromCart, emptyCart }) {

  const { height } = useDocDimensions()
  const [checkoutActive, setCheckoutActive] = useState(false)
  //console.log(cart)
  useEffect(() => {
    window.scrollTo(0, 1)
  }, [checkoutActive])
  return (
    <Container
      style={{
        alignItems: 'flex-start',
      }}
    >
      <Container
        style={{
          top: '0px',
          height: headerHeight,
          fontSize: isMobile ? '24px' : '32px',
          fontFamily: 'Oswald'
        }}
      >
        Checkout
      </Container>
      <Container
        style={{
          top: headerHeight,
          borderTop: '1px solid #efefef',
          height: height - headerHeight
          //height: `${(imageLength + 2) * Math.max(props.pieces.length / galleryColumns, 1)}`
        }}
      >
        <ShoppingCart setCheckoutActive={setCheckoutActive} removeFromCart={removeFromCart} cart={cart} />
        <Checkout emptyCart={emptyCart} active={checkoutActive} cart={cart} goBack={() => setCheckoutActive(false)} />
      </Container>
    </Container>
  )
}

function PublicPage() {

  const [pieces, setPieces] = useState(null)
  const [cart, setCart] = useState([])
  const [featured, setFeatured] = useState(null)
  const { lookbookImages } = useLookbook()


  const emptyCart = () => {
    setCart([])
  }

  const reloadPieces = () => {
    getPieces(pieces => setPieces(pieces))
    getFeatured(featured => setFeatured(featured))
  }

  useEffect(() => {
    if (!pieces) {
      getPieces(pieces => setPieces(pieces))
      getFeatured(featured => setFeatured(featured))
    }
  }, [pieces])

  const addToCart = useCallback((item) => {
    if (!item.sold) {
      if (cart.indexOf(item) === -1) {
        setCart(cart => [...cart, item])
      }
    }
  }, [cart])

  const removeFromCart = useCallback((item) => {
    let newCart = [...cart]
    let itemIndex = newCart.indexOf(item)
    if (itemIndex !== -1) {
      newCart.splice(itemIndex, 1)
      setCart(newCart)
    }
  }, [cart])


  const pages = useMemo(() => ({
    // 'Lookbook': {
    //   icon: '/images/icons/lookbook.png',
    //   path: '/',
    //   component: Lookbook,
    //   data: {
    //     images: lookbookImages || [],
    //     name: featured && featured.season
    //   }
    // },
    'Shop': {
      icon: '/images/icons/shop.png',
      path: '/',
      component: Shop,
      data: {
        pieces: pieces ? pieces.filter(piece => piece.price) : [],
        cart,
        removeFromCart,
        addToCart
      }
    },
    'Lookbook': {
      icon: '/images/icons/shop.png',
      path: '/lookbook',
      component: Archive,
      data: {
        pieces
      }
    },
    'Checkout': {
      path: '/checkout',
      component: CheckoutPage,
      data: {
        cart: cart || [],
        removeFromCart,
        emptyCart
      }
    }
  }), [pieces, cart, featured, lookbookImages])

  return (

    <PiecesContext.Provider value={{ reloadPieces }} >
      <PageRouter cart={cart} loaded={pieces && featured && lookbookImages} pages={pages} />
    </PiecesContext.Provider>
  );
}

function App(props) {

  const [loading, setLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(null)
  const messageRef = useRef()
  const confirmRef = useRef()

  const showMessage = message => {
    messageRef.current.showMessage(message)
  }

  const prompt = (message, yesCallback, noCallback) => {
    confirmRef.current.prompt(message, yesCallback, noCallback)
  }


  return (
    <Router>
      <PopUpsContext.Provider value={{ showMessage, prompt, setLoading, loading, setLoadingMessage }}>
        <Switch>
          <Route exact path='/' component={PublicPage} />
          <Route path="/admin" component={AdminPage} />
          <Route component={PublicPage} />
        </Switch>
        <PopUpMessage ref={messageRef} />
        <PopUpLoadingScreen show={loading} message={loadingMessage} />
        <PopUpConfirmBox ref={confirmRef} />
      </PopUpsContext.Provider>
    </Router>
  )
}

export default App;
