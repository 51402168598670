import React, {useRef, useState, useEffect} from 'react'
import Container from '../Container'

function ImageLoader({ src, noIcon, style, invert, alt, gray }) {

    const [loaded, setLoaded] = useState(false)
    const imageRef = useRef()

    useEffect(() => {
        if(imageRef.current.complete){
            setLoaded(true)
        }   
        else{
            imageRef.current.onload = () => setLoaded(true)
        }

    },[])

    return (
        <Container
            style={style}
        >
            <img
                ref={imageRef}
                style={{
                    opacity: loaded ? 1 : 0,
                    height: '95%',
                    objectFit: 'contain',
                    transition: 'opacity 250ms ease-out'
                }}
                src={src}
                alt={alt || ''}
            />
            <Container
                style={{
                    opacity: (loaded || noIcon) ? 0 : 1,
                    background: invert ? 'rgba(255,255,255,0.8)' : (gray ? null : 'rgba(0,0,0,0.8)'),
                    transition: 'opacity 250ms ease-out'
                }}
            >
                <img src='/images/rolling.svg' alt='loading' style={{height: '100px', width: '100px', maxHeight: '90%', maxWidth: '90%', objectFit: 'contain', filter: invert ? 'invert(100%)' : (gray ? 'invert(50%)' : null) }}/>
            </Container>
        </Container>
    )
}

export default ImageLoader