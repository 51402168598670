import React, {forwardRef} from 'react'

function Container(props, ref) {

    return (
      <div
        ref={ref}
        id={props.id}
        onClick={props.onClick}
        onMouseEnter={props.onMouseEnter}
        onMouseLeave={props.onMouseLeave}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...props.style
        }}
      >
        {props.children}
      </div>
    )
  }

  export default forwardRef(Container)
  