import React from 'react';
import Container from '../Container'
import { TextInput } from '../Inputs';


function FormSection({ name, fields, handleChange }) {
    return (
      <Container
        style={{
          position: 'relative',
          height: 'auto',
          flexFlow: 'column',
          borderBottom: '1px solid #cdcdcd'
        }}
      >
        <Container
          style={{
            position: 'relative',
            height: '50px',
            fontFamily: 'Oswald',
            fontSize: '24px',
            //alignItems: 'flex-end'
          }}
        >
          {name}
        </Container>
        {
          fields.map((field, index) =>
            <Container
              key={`${name}_form_${field}_${index}`}
              style={{
                position: 'relative',
                height: '50px',
                width: '90%',
              }}
            >
              <TextInput style={{ fontFamily: 'Oswald', fontSize: '20px' }} required={field.required} label={field.label} name={field.name} autocomplete={field.autocomplete} value={field.value} onChange={value => handleChange(name, index, value)} />
            </Container>
          )
        }
        <Container
          style={{
            position: 'relative',
            height: '20px',
            fontFamily: 'Oswald',
            fontSize: '24px',
            alignItems: 'flex-end'
          }}
        />
      </Container>
    )
  }

  export default FormSection