
import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBiA3-5lZm_vc-UWwfYYbuftgbs5Mtxez8",
    authDomain: "kolton-website.firebaseapp.com",
    databaseURL: "https://kolton-website.firebaseio.com",
    projectId: "kolton-website",
    storageBucket: "kolton-website.appspot.com",
    messagingSenderId: "206756129865",
    appId: "1:206756129865:web:36adff7183092bff"
};
// Initialize Firebase
if (!firebase.apps.length)
    firebase.initializeApp(firebaseConfig);

var storage = firebase.storage();
let db = firebase.firestore()
let piecesDb = db.collection('pieces')

const savePiece = (piece, callback) => {

    setPieceImage(piece, ({ error, piece }) => {
        if (error) {
            callback({error})
        }
        else {
            console.log(piece)
            piecesDb.doc(piece.id).set(piece).then(() => {
                callback({ updatedPiece: piece })
            })
            .catch(err => {
                console.log(err)
                callback({ error: 'Issue saving piece in DB' })
            })
        }
    })
}

const deletePiecePhotos = (piece, callback) => {
    let pieceStorageRef = storage.ref(`pieces/${piece.id}/photos`)
    pieceStorageRef.listAll().then(res => {
        let items = res.items
        items.forEach((item, index) => {
            item.delete().then(res => {
                //console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
            if(index === items.length - 1){
                callback(piece)
            }
        })
        //callback(piece)
    })
    .catch(err => {
        console.log(err)
        callback({error: 'problem deleting storage'})
    })
    //console.log(pieceStorageRef)
}

const deletePiece = (piece, callback) => {
    deletePiecePhotos(piece, ({error}) => {
        if(error){
            callback({error: 'Something went wrong deleting files'})
        }
        else{
            piecesDb.doc(piece.id).delete().then(() => {
                callback({success: true})
            })
            .catch(err => {
                console.log(err)
                callback({error: 'Something went wrong deleting from DB'})
            })
        }
    })
}

const saveNewPiece = (piece, callback) => {
    let newPieceDoc = piecesDb.doc()
    piece.id = newPieceDoc.id
    savePiece(piece, callback)
}

const setPieceImage = (piece, callback) => {
    if (piece.newImage) {
        let pieceImageRef = storage.ref(`pieces/${piece.id}/photos/main.png`);
        pieceImageRef.put(piece.newImage.file).then(snapshot => {
            snapshot.ref.getDownloadURL().then(url => {
                piece.image = url
                delete piece.newImage
                callback({piece})
            })
        })
        .catch(err => {
            console.log(err)
            callback({error: 'Something went wrong lmk'})
        })
    }
    else {
        callback({ piece })
    }
}

const getPieceImage = (piece, callback) => {

    const preloadImage = (url, callback) => {
        let img = new Image()
        img.onload = callback
        img.src = url
    }

    //console.log(piecesStorage)
    let pieceImageRef = storage.ref(`pieces/${piece.id}/photos/main.png`);
    //console.log(pieceImageRef)
    pieceImageRef.getDownloadURL().then(url => {
        preloadImage(url, () => {
            piece.image = url
            callback(piece)
        })
    })
        .catch(piece => {
            callback(piece)
        })
    //console.log(piece)
}

const getPieceById = (id, callback) => {
    const pieceRef = piecesDb.doc(id)
    pieceRef.get().then(function (doc) {
        if (doc.exists) {
            let piece = { ...doc.data(), id: doc.id }
            getPieceImage(piece, piece => {
                callback(piece)
            })
        } else {
            // doc.data() will be undefined in this case
            //console.log("No such document!");
        }
    }).catch(function (error) {
        console.log("Error getting document:", error);
    });
}


const getPieceImages = (pieces, callback) => {

    const recursiveGetImages = (ids, images, callback) => {
        if(ids.length === 0){
            callback(images)
        }
        else{
            storage.ref(`pieces/${ids[0]}/photos/main.png`).getDownloadURL().then(url => {
                images[ids[0]] = url
                recursiveGetImages(ids.slice(1), images, callback)
            }).catch(err => {
                console.log(err)
                recursiveGetImages(ids.slice(1), images, callback)
            })
        }
    }

    const ids = pieces.map(piece => piece.id)
    recursiveGetImages(ids, {}, callback)
}

const getPieces = callback => {
    piecesDb.get().then(querySnapshot => {
        let pieces = querySnapshot.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
        })
        getPieceImages(pieces, images => {
            pieces.forEach((piece, index) => {
                if(images[piece.id]){
                    piece.image = images[piece.id]
                }
                else{
                    pieces.splice(index, 1)
                }
            })
            callback(pieces)
        })
    })
}

const getNestedPieces = pieces => {
    let seasonNames = getSeasonNames(pieces)

    let seasons = {}

    seasonNames.forEach(seasonName => {
        seasons[seasonName] = pieces.filter(piece => piece.seasonName === seasonName)
    })
    return ({ seasons, freePieces: pieces.filter(piece => !piece.seasonName) })
}

const getSeasonNames = pieces => {
    let newSeasonNames = []
    for (let i = 0; i < pieces.length; i++) {
        let seasonName = pieces[i].seasonName
        if (seasonName) {
            if (newSeasonNames.indexOf(seasonName) === -1) {
                newSeasonNames.push(seasonName)
            }
        }
    }
    return newSeasonNames
}

const renameSeason = (oldName, newName, callback) => {
    let seasonBatch = db.batch()
    piecesDb.where('seasonName', '==', oldName).get().then(snapshot => {
        for(let i = 0; i < snapshot.docs.length; i ++){
            let docRef = snapshot.docs[i].ref
            seasonBatch.update(docRef, {seasonName: newName})
        }
        seasonBatch.commit().then(res => {
            callback(true)
        })
        .catch(err => {
            console.log(err)
            callback(false)
        })
    })
}

const setCartSold = (cart, callback) => {
    let soldBatch = db.batch()
    cart.forEach(item => {
        let pieceRef = piecesDb.doc(item.id)
        soldBatch.update(pieceRef, {sold: true})
    })

    soldBatch.commit().then(() =>
        callback(true)
    ).catch(() =>
        callback(false)
    )
}
export { getPieceImage, getPieceById, setCartSold, getPieces, savePiece, deletePiece, getSeasonNames, getNestedPieces, renameSeason, saveNewPiece }
export default { getPieceImage, getPieceById, setCartSold, getPieces, savePiece, deletePiece, getSeasonNames, getNestedPieces, renameSeason, saveNewPiece }