import React, {useState, useEffect, useImperativeHandle, forwardRef} from 'react'
import {SimpleHoverButton} from './Inputs'
import Container from './Container'

const transitionCurve = 'cubic-bezier(0.645, 0.045, 0.355, 1)'

function PopUpConfirmBox(props, ref) {
    const [active, setActive] = useState(false)
    const [message, setMessage] = useState(null)
    const [yesCallback, setYesCallback] = useState(null)
    const [noCallback, setNoCallback] = useState(null)


    useImperativeHandle(ref, () => ({
        prompt: (newMessage, newYesCallback, newNoCallback) => {
            console.log(newYesCallback)
            setMessage(newMessage)
            setYesCallback(() => newYesCallback)
            setNoCallback(() => newNoCallback)
            setActive(true)
        }
    }))

    const handleYes = () => {
        if (yesCallback) yesCallback()
        setActive(false)
    }

    const handleNo = () => {
        setActive(false)
        if (noCallback) noCallback()
    }

    return (
        <Container
            style={{
                position: 'fixed',
                zIndex: '2',
                bottom: '0px',
                top: '0px',
                right: '0px',
                left: '0px',
                pointerEvents: active ? 'auto' : 'none',
                opacity: active ? 1 : 0,
                background: 'rgba(0,0,0,0.8)',
                transition: `opacity 250ms ${transitionCurve}`
            }}
        >
            <Container
                style={{
                    position: 'relative',
                    width: 'auto',
                    padding: '20px',
                    height: '100px',
                    //background: 'white',
                    flexFlow: 'column',
                    borderRadius: '4px',
                }}
            >
                <Container
                    style={{
                        position: 'relative',
                        height: '70px',
                        fontSize: '22px',
                        color: '#efefef',
                        textAlign: 'center'
                    }}
                >
                    {message || ''}
                </Container>
                <Container
                    style={{
                        position: 'relative',
                        height: '30px'
                    }}
                >
                    <Container
                        style={{
                            position: 'relative',
                            width: '50%',
                        }}
                    >
                        <SimpleHoverButton idleColor='#cdcdcd' hoverColor='#efefef' style={{fontSize: '24px'}} onClick={handleYes}>
                            YES
                        </SimpleHoverButton>
                    </Container>
                    <Container
                        style={{
                            position: 'relative',
                            width: '50%',
                        }}
                    >
                        <SimpleHoverButton idleColor='#cdcdcd' hoverColor='#efefef' style={{fontSize: '24px'}} onClick={handleNo}>
                            NO
                        </SimpleHoverButton>
                    </Container>
                </Container>
            </Container>

        </Container>
    )
}

PopUpConfirmBox = forwardRef(PopUpConfirmBox)

function PopUpMessage(props, ref) {
    const [active, setActive] = useState(false)
    const [message, setMessage] = useState(null)
    const [messageTimeout, setMessageTimeout] = useState(null)

    useImperativeHandle(ref, () => ({
        showMessage: (message, callback) => {
            setMessage(message)
            setActive(true)
        }
    }))

    useEffect(() => {
        if (active) {
            setMessageTimeout(setTimeout(() => setActive(false), 2500))
        }
        else{
           setMessageTimeout(null)
        }
    }, [active])

    return (
        <Container
            onClick={() => {if(active){setActive(false); clearTimeout(messageTimeout)}}}
            style={{
                position: 'fixed',
                zIndex: '2',
                bottom: '0px',
                top: '0px',
                right: '0px',
                left: '0px',
                pointerEvents: active ? 'auto' : 'none',
                opacity: active ? 1 : 0,
                background: 'rgba(0,0,0,0.9)',
                transition: `opacity 250ms ${transitionCurve}`
            }}
        >
            <Container
                style={{
                    width: '90%',
                    padding: '20px',
                    height: '100px',
                    //background: 'white',
                    color: '#efefef',
                    borderRadius: '4px',
                    fontSize: '22px',
                    textAlign: 'center',
                    whiteSpace: 'pre-wrap'
                }}
            >
                {message || ''}
            </Container>

        </Container>
    )
}

PopUpMessage = forwardRef(PopUpMessage)


function PopUpLoadingScreen(props) {

    return (
        <Container
            style={{
                position: props.contained ? 'absolute' : 'fixed',
                zIndex: '2',
                bottom: '0px',
                top: '0px',
                right: '0px',
                left: '0px',
                //fontSize: '14px',
                color: 'white',
                flexFlow: 'column',
                pointerEvents: props.show ? 'auto' : 'none',
                opacity: props.show ? 1 : 0,
                background: 'rgba(0,0,0,0.8)',
                transition: `opacity 250ms ${transitionCurve}`
            }}
        >
                <img style={{height: '100px', width: '100px'}} src='/images/rolling.svg' alt='loading...'/>
                <p>{props.message}</p>
        </Container>
    )
}

export {PopUpMessage, PopUpConfirmBox, PopUpLoadingScreen}