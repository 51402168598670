import React, {useState, useEffect, useMemo} from 'react'
import utils from '../../utils/metaUtils'

function useFeatured(){

    const [featured, setFeatured] = useState(null)

    useEffect(() => {
        utils.getFeatured(featured => setFeatured(featured))
    }, [])

    const setFeaturedSeason = (seasonName, callback) => {
        utils.setFeaturedSeason(seasonName, res => {
            if (res) {
                utils.getFeatured(featured => setFeatured(featured))
                callback({success: true})
            }
            else {
                callback({error: 'Error setting season'})
            }
        })
    }

    const adminFeaturedFunctions = useMemo(() => ({
        setFeaturedSeason
    }), [setFeaturedSeason])

    return {featured, adminFeaturedFunctions}
}

export default useFeatured