
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom'
import Container from '../Container'

function PageMenuItem({ pageName, page, select, selected }) {

    const [hovered, setHovered] = useState(false)
  
    return (
      <Container
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={select}
        style={{
          position: 'relative',
          cursor: 'pointer',
          opacity: (selected || hovered) ? 0.9 : 0.6,
          height: '50px',
          fontSize: '28px',
          fontFamily: 'Blinker',
          width: '50px',
          justifyContent: 'flex-start'
        }}
      >
        <Container>
          <img src={page.icon} alt={pageName + '_icon'} style={{ height: '80%', objectFit: 'contain' }} />
        </Container>
        <Container
          style={{
            left: '-110px',
            width: '100px',
            justifyContent: 'flex-end',
            opacity: (selected || hovered) ? 1 : 0,
            transition: 'opacity 250ms ease-out'
          }}
        >
          {pageName.toUpperCase()}
        </Container>
      </Container>
    )
  }
  
  function PageMenu({ pages, location, history }) {
  
    const pageNames = Object.keys(pages)
  
    const selectPage = pageName => {
      history.push(pages[pageName].path)
    }
  
    return (
      <Container
        style={{
          top: '20px',
          right: '20px',
          width: '200px',
          height: pageNames.length * 50,
          flexFlow: 'column',
          color: '#efefef',
          alignItems: 'flex-end',
          justifyContent: 'flex-start'
        }}
      >
        {
          pageNames.map(pageName =>
            <PageMenuItem select={() => selectPage(pageName)} selected={location.pathname === pages[pageName].path} pageName={pageName} page={pages[pageName]} />
          )
        }
      </Container>
    )
  }
  
  export default withRouter(PageMenu)