import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

import Container from '../Container'
import useDocDimensions from '../../hooks/useDocDimensions';

function MobilePageMenuItem({ pageName, page, select }) {
  return (
    <Container
      onClick={select}
      style={{ position: 'relative', height: '60px', borderBottom: '1px solid rgba(255,255,255,0.2)' }}
    >
      <Container
        style={{
          width: '50%',
        }}
      >
        <Container
          style={{
            position: 'relative',
            height: '50px',
            width: 'auto',
            color: '#808080',
            //justifyContent: 'flex-start',
            fontSize: '28px',
            fontFamily: 'Oswald',
          }}
        >
          {pageName.toUpperCase()}
        </Container>
      </Container>
    </Container>
  )
}

function InstagramLogo({ show, pageLength }) {

  return (
    <Container
      onClick={() => window.location.href = 'https://www.instagram.com/allonemfg/'}
      style={{
        top: '0px',
        right: '0px',
        width: isMobile ? '80px' : '100px',
        height: `${isMobile ? 80 : 100}px`,
        transition: 'opacity 250ms ease-out',
        opacity: show ? '0.6' : '0'
      }}
    >
      <img src={'/images/instagram.png'} alt='instagram logo' style={{ objectFit: 'contain', height: '50%' }} />
    </Container>
  )
}

function MobilePageMenu({ pages, location, history }) {

  const { width, height } = useDocDimensions()
  const menuTileLength = 3
  let menuTileArray = []
  for (let i = 0; i < menuTileLength; i++) {
    menuTileArray.push(i)
  }

  const pageNames = Object.keys(pages)
  const [active, setActive] = useState(false)


  const selectPage = pageName => {
    history.push(pages[pageName].path)
  }


  useEffect(() => {
    setActive(false)
  }, [location])

  //console.log(pages)

  return (
    <Container
      style={{
        position: 'fixed',
        top: '0px',
        height: height,
        width: width,
        pointerEvents: active ? 'auto' : 'none'
      }}
    >
      <Container
        style={{
          left: active ? 0 : width * -1,
          opacity: active ? 1 : 0,
          background: 'rgb(255,255,255)',
          transition: 'all 250ms ease-in'
        }}
      >
        <InstagramLogo show={active} />
        <Container
          style={{
            top: '80px',
            bottom: '0px',
            alignItems: 'flex-start'
          }}
        >
          <Container
            style={{
              height: 'auto',
              flexFlow: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              borderTop: '1px solid rgba(255,255,255,0.2)'
            }}
          >
            {
              pageNames.filter(pageName => pageName !== 'Checkout').map((pageName, index) =>
                <MobilePageMenuItem key={`mobile_page_menu_item_${index}`} pageName={pageName} page={pages[pageName]} select={() => selectPage(pageName)} />
              )
            }
          </Container>
        </Container>
      </Container>
      <Container
        style={{
          top: '0px',
          left: '0px',
          height: isMobile ? '80px' : '100px',
          width: isMobile ? '80px' : '100px',
        }}
      >
        <Container
          style={{
            height: isMobile ? '80px' : '100px',
            width: isMobile ? '80px' : '100px',
            right: '0px',
            top: '0px'
          }}
        >
          <svg
            onClick={() => setActive(true)}
            viewBox='0 0 100 100' style={{
              position: 'absolute',
              minHeight: '40px',
              width: '40px',
              // opacity: menuState ? 0 : 1,
              transform: active ? 'scaleY(0)' : 'scaleY(1)',
              transition: `transform 250ms ease-out`,
              pointerEvents: 'auto'
              //filter: 'drop-shadow(1px 1px 2px #333)'
            }}>
            <path
              d='M 25 30 L 75 30'
              stroke='#808080'
              strokeWidth='6'
              strokeLinecap='round'
            />
            <path
              d='M 25 50 L 75 50'
              stroke='#808080'
              strokeWidth='6'
              strokeLinecap='round'
            />
            <path
              d='M 25 70 L 75 70'
              stroke='#808080'
              strokeWidth='6'
              strokeLinecap='round'
            />
          </svg>
          <svg
            onClick={() => setActive(false)}
            viewBox='0 0 100 100' style={{
              position: 'absolute',
              minHeight: '30px',
              width: '30px',
              // opacity: menuState ? 0 : 1,
              transform: active ? 'scaleY(1)' : 'scaleY(0)',
              transition: `transform 250ms ease-out`,
              pointerEvents: 'auto'
              //filter: 'drop-shadow(1px 1px 2px #333)'
            }}>
            <path
              d='M 25 50 L 75 25'
              stroke='#808080'
              strokeWidth='6'
              strokeLinecap='round'
            />
            <path
              d='M 25 50 L 75 75'
              stroke='#808080'
              strokeWidth='6'
              strokeLinecap='round'
            />
          </svg>
        </Container>
      </Container>
    </Container>
  )
}

export default withRouter(MobilePageMenu)
