import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/auth'

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyBiA3-5lZm_vc-UWwfYYbuftgbs5Mtxez8",
    authDomain: "kolton-website.firebaseapp.com",
    databaseURL: "https://kolton-website.firebaseio.com",
    projectId: "kolton-website",
    storageBucket: "kolton-website.appspot.com",
    messagingSenderId: "206756129865",
    appId: "1:206756129865:web:36adff7183092bff"
};
// Initialize Firebase
if (!firebase.apps.length)
    firebase.initializeApp(firebaseConfig);

let storage = firebase.storage();
let storageRef = storage.ref()
let db = firebase.firestore()

const recursiveGetUrls = (snapshots, images, callback) => {
    if (snapshots.length === 0) {
        callback(images)
    }
    else {
        storage.ref(`/lookbook/${snapshots[0].id}/main.png`).getDownloadURL().then(url => {
            images.push({ url, id: snapshots[0].id, ...snapshots[0].data()})
            recursiveGetUrls(snapshots.slice(1), images, callback)
        }).catch(err => {
            console.log(err)
            recursiveGetUrls(snapshots.slice(1), images, callback)
        })
    }
}

const getLookbookImages = callback => {
    db.collection('lookbook').get().then(snapshot => {
        recursiveGetUrls(snapshot.docs, [], images => {
            callback({images})
        })
    }).catch(err => {
        console.log(err)
        callback({error: 'Error getting lookbook images'})
    })

}

const deleteImage = (image, callback) => {
    console.log(image)
    db.collection('lookbook').doc(image.id).delete().then(() => {
        storageRef.child(`lookbook/${image.id}/main.png`).delete().then(() => {
            callback({ success: true })
        }).catch(err => {
            console.log(err)
            callback({ error: 'Error deleting image from storage' })
        })
    }).catch(err => {
        console.log(err)
        callback({error: 'Error deleting image from db'})
    })

}

const addImages = (images, startIndex, callback) => {

    const recursiveImageUpload = (images, imagesWithUrls, callback) => {
        if(images.length === 0){
            callback(imagesWithUrls)
        }
        else{
            let imagePath = `lookbook/${images[0].id}/main.png`
            storage.ref(imagePath).put(images[0].file).then(() => {
                storage.ref(imagePath).getDownloadURL().then(url => {
                    imagesWithUrls.push({...images[0], url})
                    recursiveImageUpload(images.slice(1), imagesWithUrls, callback)
                }).catch(err => {
                    console.log(err)
                    recursiveImageUpload(images.slice(1), imagesWithUrls, callback)
                })
            }).catch(err => {
                console.log(err)
                recursiveImageUpload(images.slice(1), imagesWithUrls, callback)
            })
        }
    }

    //images is a list of files
    if(images.length){
        let batch = db.batch()
        let newImages = []
        images.forEach((image, index) => {
            let order = startIndex + index
            let imgRef = db.collection('lookbook').doc()
            newImages.push({id: imgRef.id, order, file: image})
            batch.set(imgRef, {order})
        })
        batch.commit().then(() => {
            recursiveImageUpload(newImages, [], imagesWithUrls => {
                callback({images: imagesWithUrls})
            })
        }).catch(err => {
            callback({error: 'Batch db write failed...'})
        })
    }
    else{
        callback({error: 'No images added...'})
    }
}

export { getLookbookImages, deleteImage, addImages }
export default { getLookbookImages, deleteImage, addImages }