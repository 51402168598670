
import React, {useState, useEffect, useMemo} from 'react'

import usePieces from '../../hooks/admin/usePieces'
import useFeatured from '../../hooks/admin/useFeatured'
import useLookbook from '../../hooks/admin/useLookbook'
import useLoadingDots from '../../hooks/useLoadingDots'

import Container from '../Container'
import SlidingMenuRouter from '../routing/SlidingMenuRouter'

import AdminPiecesTab from './tabs/AdminPiecesTab'
import AdminLookbookTab from './tabs/AdminLookbookTab'


function AdminControlPanel({ user }) {

    const { pieces, seasons, freePieces, seasonNames, adminPieceFunctions } = usePieces(true)
    const { featured, adminFeaturedFunctions } = useFeatured(true)
    const {lookbookImages, adminLookbookFunctions} = useLookbook(true)

    const numDots = useLoadingDots(!pieces)

    const adminPages = useMemo(() => ({
        'pieces': {
            component: AdminPiecesTab,
            data: {
                featured,
                seasons,
                freePieces,
                adminPieceFunctions,
                adminFeaturedFunctions,
                seasonNames
            }
        },
        'lookbook': {
            component: AdminLookbookTab,
            data: {
                lookbookImages,
                adminLookbookFunctions
            }
        }
    }), [featured, seasons, freePieces, adminPieceFunctions, adminFeaturedFunctions, seasonNames, lookbookImages, adminLookbookFunctions])

    return (
        <Container
            style={{
                alignItems: 'flex-start',
            }}
        >

            <SlidingMenuRouter pages={adminPages} />
            <Container
                style={{
                    position: 'fixed',
                    zIndex: '3',
                    opacity: (user && pieces) ? 0 : 1,
                    fontSize: '24px',
                    background: 'black',
                    color: 'white',
                    fontFamily: 'Oswald',
                    transition: 'opacity 1s ease-out',
                    pointerEvents: 'none'
                }}
            >
                {`Loading${'.'.repeat(numDots)}`}
            </Container>
        </Container>
    )
}

export default AdminControlPanel