import React, {useState, useEffect, useMemo, useCallback} from 'react'
import utils from '../../utils/lookbookUtils'

function useLookbook(auth){
    const [lookbookImages, setLookbookImages] = useState([])

    useEffect(() => {
        utils.getLookbookImages(({images}) => {
            setLookbookImages(images)
        })
    }, [])

    const deleteImage = useCallback((image, callback) => {
        const imageIndex = lookbookImages.indexOf(image)
        if(imageIndex !== -1){
            utils.deleteImage(image, ({success, error}) => {
                if(!error){
                    let newImages = [...lookbookImages]
                    newImages.splice(imageIndex, 1)
                    console.log(newImages)
                    setLookbookImages(newImages)
                    callback({success: true})
                }
                else{
                    callback({error: 'error deleting'})
                }
            })
        }
        else{
            callback({error: 'couldnt find image in list'})
        }
    }, [lookbookImages])

    const addImages = useCallback((images, callback) => {
        if(images.length){
            utils.addImages(images, lookbookImages.length, ({images, error}) => {
                if(images){
                    let newImages = [...lookbookImages, ...images]
                    console.log(newImages)
                    setLookbookImages(newImages)
                    callback({success: true})
                }
                else {
                    console.log('error adding images')
                    callback({error: 'Error adding images'})
                }
            })
        }
        else{
            callback({error: 'No images added...'})
        }
    }, [lookbookImages])


    const adminLookbookFunctions = useMemo(() => ({
        addImages,
        deleteImage,
    }), [deleteImage, addImages])

    return {lookbookImages, adminLookbookFunctions: auth ? adminLookbookFunctions : null}
}

export default useLookbook