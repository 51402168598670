import React, {useState, useEffect, useContext, useCallback, useMemo} from 'react'
import {isMobile} from 'react-device-detect'
import { useDropzone } from 'react-dropzone'

import PopUpsContext from '../../../contexts/PopUpsContext'

import useDocDimensions from '../../../hooks/useDocDimensions'

import isEquivalent from '../../../utils/isEquivalent'

import Container from '../../Container'
import {TextInput, ToggleTextInput, DropdownInput, SquareHoverButton} from '../../Inputs'
import ImageLoader from '../../images/ImageLoader'



function AdminGalleryImage({ selectPiece, piece, imageLength, handlePieceDelete }) {

    return (
        <Container
            onClick={selectPiece}
            style={{
                position: 'relative',
                width: imageLength,
                height: imageLength,
                minWidth: imageLength,
                minHeight: imageLength,
                background: 'white'
            }}
        >
            <ImageLoader alt='' src={piece.image} />
            <Container
                onClick={e => {
                    e.stopPropagation()
                    handlePieceDelete()
                }}
                style={{
                    top: '10px',
                    right: '10px',
                    width: '40px',
                    height: '40px',
                    fontSize: '24px',
                    borderRadius: '25px',
                    opacity: '0.7',
                    background: 'white'
                }}
            >
                🗑️
            </Container>
            <Container
                style={{
                    opacity: piece.sold ? 1 : 0,
                    pointerEvents: 'none',
                    bottom: '10px',
                    right: '10px',
                    width: '70px',
                    height: '50px'
                }}
            >
                <img src='/images/icons/sold.png' style={{ transform: 'rotate(-20deg)', height: '30px', objectFit: 'contain' }} />
            </Container>
        </Container>
    )
}

function SelectedImagePage({ deselect, seasonNames, dbPiece, saveChanges }) {

    const [piece, setPiece] = useState(null)
    const [imageActive, setImageActive] = useState(false)
    const { showMessage, prompt, setLoading } = useContext(PopUpsContext)

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles) {
            //console.log(acceptedFiles)// Do something with the files
            let newImageFile = acceptedFiles[0]
            if (newImageFile && (newImageFile.type === 'image/png')) {
                let reader = new FileReader()
                reader.addEventListener("load", function () {
                    handleImageChange({ file: newImageFile, dataUrl: reader.result })
                }, false);

                reader.readAsDataURL(newImageFile);
            }
            else {
                showMessage('image must be png')
            }
        }
    }, [showMessage])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const handleNameChange = (value) => {
        //console.log(value)
        setPiece(piece => ({ ...piece, name: value }))
    }

    const handlePriceChange = (value) => {

        if (!isNaN(value)) {
            setPiece(piece => ({ ...piece, price: value }))
        }
    }

    const handleSaveChanges = useCallback(() => {
        saveChanges(piece)
    }, [piece])

    const handleImageChange = (newImage) => {
        setPiece(piece => ({ ...piece, newImage }))
        setImageActive(false)
    }

    const handleSeasonNameChange = useCallback((newSeasonName) => {
        if (newSeasonName) {
            setPiece(piece => ({ ...piece, seasonName: newSeasonName }))
        }
        else {
            let newPiece = { ...piece }
            delete newPiece.seasonName
            setPiece(newPiece)
        }
    }, [piece])

    const changes = useMemo(() => {
        if (piece && dbPiece) {
            return !isEquivalent(piece, dbPiece)
        }
        else {
            return false
        }
    }, [piece, dbPiece])

    useEffect(() => {
        setPiece(dbPiece)
    }, [dbPiece])

    const handleBack = useCallback(() => {
        if (!changes) {
            deselect()
        }
        else {
            prompt('Changes not saved, go back anyway?', () => {
                deselect()
            })
        }

    }, [changes])

    const displayedImage = piece ? (piece.newImage ? piece.newImage.dataUrl : (piece.image || '/images/blanktee.png')) : null

    return (
        <Container
            style={{
                position: 'fixed',
                pointerEvents: piece ? 'auto' : 'none',
                width: '100vw',
                height: '100vh',
                background: 'rgba(255,255,255,0.9)',
                opacity: piece ? 1 : 0,
                transition: `opacity 250ms ease-out`,
            }}
        >
            <Container
                style={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    textAlign: 'center',
                    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                    flexFlow: 'column',
                    justifyContent: 'flex-start'
                }}
            >
                <Container
                    style={{
                        position: 'relative',
                        background: 'white',
                        height: imageActive ? '0%' : '10%',
                        alignItems: 'flex-start',
                        transition: 'height 250ms ease-out'
                    }}
                >
                    <Container
                        onClick={handleSaveChanges}
                        style={{
                            right: '0px',
                            height: '80px',
                            width: changes ? '80px' : '0px',
                            opacity: changes ? 1 : 0,
                            transition: 'all 250ms ease-out',
                            overflow: 'hidden'
                        }}
                    >
                        <img src='/images/icons/checkmark.png' style={{ width: '50px', objectFit: 'contain', filter: 'invert(30%)' }} alt='save' />
                    </Container>
                </Container>
                <Container
                    onClick={handleBack}
                    style={{
                        top: '0px',
                        left: '0px',
                        width: '80px',
                        height: '80px',
                    }}
                >
                    <img src='/images/icons/downarrow.png' style={{ height: '50px', width: '30px', filter: 'invert(50%)', transform: 'rotate(90deg)' }} alt='save' />
                </Container>
                <Container
                    style={{
                        position: 'relative',
                        background: 'white',
                        height: imageActive ? '100%' : '50%',
                        transition: 'height 250ms ease-out'
                    }}
                >
                    {
                        piece && <ImageLoader invert style={{ position: 'absolute', top: '20px', height: '70%', maxWidth: '100%' }} src={displayedImage} alt='image display' />
                    }
                    <Container
                        {...getRootProps({
                            style: {
                                height: '80px',
                                opacity: imageActive ? 1 : 0,
                                pointerEvents: imageActive ? 'auto' : 'none',
                                width: '80px',
                                right: '20px',
                                top: '20px',
                                transition: 'opacity 250ms ease-out'
                            }
                        })}

                    >
                        <input {...getInputProps({ accept: 'image/png' })} />
                        <img src='/images/icons/upload.png' style={{ width: '60px', objectFit: 'contain', filter: 'invert(25%)' }} alt='save' />
                    </Container>
                    <Container
                        onClick={() => setImageActive(imageActive => !imageActive)}
                        style={{
                            bottom: '50px',
                            height: '40px',
                            transform: `scaleY(${imageActive ? -1 : 1})`,
                            transition: 'transform 500ms ease-out'
                        }}
                    >
                        <img src='/images/icons/downarrow.png' style={{ height: '40px', objectFit: 'contain', filter: 'invert(50%)' }} alt='save' />
                    </Container>
                </Container>
                <Container
                    style={{
                        position: 'relative',
                        height: imageActive ? '0%' : '40%',
                        overflow: 'hidden',
                        flexFlow: 'column',
                        background: 'white',
                        transition: 'height 250ms ease-out'
                    }}
                >
                    <Container
                        style={{
                            position: 'relative',
                            height: '50px',
                            width: '80%',
                            maxWidth: '300px'
                        }}
                    >
                        <Container
                            style={{
                                height: '40px',
                                alignItems: 'flex-start'
                            }}
                        >
                            <DropdownInput placeholder='Season' options={seasonNames} style={{ height: '40px', fontSize: '24px' }} value={piece ? piece.seasonName || '' : ''} onChange={handleSeasonNameChange} />
                        </Container>
                    </Container>
                    <Container
                        style={{
                            position: 'relative',
                            height: '80px',
                            width: '80%',
                            maxWidth: '300px'
                        }}
                    >
                        <Container
                            style={{
                                height: '50px'
                            }}
                        >
                            <TextInput placeholder='Name' style={{ height: '50px', fontSize: '24px' }} value={piece ? piece.name : ''} onChange={handleNameChange} />
                        </Container>
                    </Container>
                    <Container
                        style={{
                            position: 'relative',
                            height: '60px',
                            width: '80%',
                            maxWidth: '300px'
                        }}
                    >
                        <Container
                            style={{
                                height: '50px'
                            }}
                        >
                            <TextInput placeholder='Price' style={{ height: '50px', fontSize: '20px' }} value={piece ? piece.price : ''} onChange={handlePriceChange} />
                            <Container
                                style={{
                                    height: '50px',
                                    width: '20px',
                                    left: '10px',
                                    fontSize: '20px',
                                    fontFamily: 'Oswald',
                                    pointerEvents: 'none'
                                }}
                            >
                                $
                            </Container>
                        </Container>
                    </Container>
                </Container>
            </Container>
        </Container>
    )
}


function Season({ name, editName, pieces, selectPiece, addPiece, handlePieceDelete, featured, setFeatured }) {

    const { width } = useDocDimensions()

    const imageLength = isMobile ? width / 3 : width / 5

    return (
        <Container
            style={{
                position: 'relative',
                flexFlow: 'row wrap',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                height: 'auto'
            }}
        >
            {
                name ?
                    <Container
                        style={{
                            background: '#efefef',
                            position: 'relative',
                            height: '50px',
                            fontSize: '24px',
                            fontFamily: 'Oswald'
                        }}
                    >
                        <ToggleTextInput value={name} editValue={editName} />
                        <Container
                            onClick={setFeatured}
                            style={{
                                height: '50px',
                                width: '50px',
                                left: '0px'
                            }}
                        >
                            <img src='/images/icons/star.png' style={{ width: '50%', objectFit: 'contain', filter: `grayscale(${featured ? 0 : 100}%)`, transition: 'filter 250ms ease-out' }} alt='save' />
                        </Container>
                    </Container>
                    :
                    <Container
                        style={{
                            background: '#efefef',
                            position: 'relative',
                            height: '50px',
                        }}
                    >
                        {name}
                    </Container>
            }
            {pieces && pieces.map((piece, index) =>
                <AdminGalleryImage handlePieceDelete={() => handlePieceDelete(piece)} key={`admin_gallery_image_${index}`} selectPiece={() => selectPiece(piece)} piece={piece} imageLength={imageLength} />
            )}
            <SquareHoverButton onClick={addPiece} width={imageLength}>+</SquareHoverButton>
        </Container>
    )
}

function AdminPiecesTab({ pieces, seasons, seasonNames, freePieces, featured, adminPieceFunctions, adminFeaturedFunctions, setMenuVisible }) {

    const [selectedPiece, setSelectedPiece] = useState(null)

    const handlePieceSelect = piece => {
        setSelectedPiece(piece)
    }

    useEffect(() => {
        if(selectedPiece){
            setMenuVisible(false)
        }
        else{
            setMenuVisible(true)
        }
    }, [selectedPiece])

    const handlePieceAdd = (seasonName) => {
        console.log(seasonName)
        if (seasonName) {
            setSelectedPiece({ name: '', price: '', seasonName })
        }
        else {
            setSelectedPiece({ name: '', price: '' })
        }
    }

    const { showMessage, prompt, setLoading } = useContext(PopUpsContext)

    const handlePieceDelete = (piece) => {
        prompt('Delete this?', () => {
            adminPieceFunctions.deletePiece(piece, () => {

            })
        })
    }

    const handleSetFeatuedSeason = (name) => {
        adminFeaturedFunctions.setFeaturedSeason(name, () => { })
    }

    const handleSeasonRename = (oldName, newName) => {
        adminPieceFunctions.renameSeason(oldName, newName, (res) => {
            if (res) {
                if (oldName === featured.season) {
                    handleSetFeatuedSeason(newName)
                }
            }
        })
    }

    const savePiece = piece => {
        if (!piece.id) {
            if (piece.newImage && piece.newImage.dataUrl) {
                setLoading(true)
                adminPieceFunctions.saveNewPiece(piece, ({ updatedPiece }) => {
                    setSelectedPiece(updatedPiece)
                    //deselect()
                })
            }
            else {
                showMessage('gotta add a pic')
            }
        }
        else {
            setLoading(true)
            adminPieceFunctions.savePiece(piece, ({ updatedPiece }) => {
                setLoading(false)
            })
        }
    }
    return (
        <Container
            style={{
                position: 'relative',
                flexFlow: 'column',
                justifyContent: 'flex-start',
                height: 'auto'
            }}
        >
            {
                (featured && seasons) &&
                Object.keys(seasons).map(seasonName =>
                    <Season setFeatured={() => handleSetFeatuedSeason(seasonName)} featured={seasonName === featured.season} key={`admin_season_${seasonName.toLowerCase()}`} addPiece={() => handlePieceAdd(seasonName)} editName={handleSeasonRename} name={seasonName} pieces={seasons[seasonName]} handlePieceDelete={handlePieceDelete} selectPiece={handlePieceSelect} />
                )
            }
            {
                freePieces && <Season addPiece={() => handlePieceAdd()} pieces={freePieces} handlePieceDelete={handlePieceDelete} selectPiece={handlePieceSelect} />
            }
            <SelectedImagePage
                saveChanges={savePiece}
                updatePiece={adminPieceFunctions.updatePiece}
                dbPiece={selectedPiece}
                deselect={() => handlePieceSelect(null)}
                showMessage={showMessage}
                prompt={prompt}
                seasonNames={seasonNames}
            />
        </Container>
    )
}

export default AdminPiecesTab