import React, { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'

import Container from '../components/Container'
import useDocDimensions from '../hooks/useDocDimensions'
import ImageLoader from '../components/images/ImageLoader'


function SelectedShopPiece({ inCart, piece, goBack, addToCart, removeFromCart }) {

  const [imageActive, setImageActive] = useState(false)
  const { height, width } = useDocDimensions()
  const [justAdded, setJustAdded] = useState(false)

  useEffect(() => {
    if (!piece) {
      setImageActive(false)
    }
  }, [piece])

  const handleClick = () => {
    if (!inCart) {
      setJustAdded(true)
      addToCart()
    }
    else {
      removeFromCart()
    }
  }

  useEffect(() => {
    if (justAdded) {
      setTimeout(() => setJustAdded(false), 300)
    }

  }, [justAdded])

  //console.log(inCart)

  return (
    <Container
      style={{
        position: 'fixed',
        top: '0px',
        bottom: '0px',
        pointerEvents: piece ? 'auto' : 'none',
        width: width,
        height: null,
        fontFamily: 'Oswald',
        background: 'rgba(255,255,255,0.9)',
        opacity: piece ? 1 : 0,
        transition: `opacity 250ms ease-out`,
      }}
    >
      <Container
        style={{
          position: 'relative',
          background: 'white',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
          flexFlow: 'column',
          //justifyContent: 'flex-start'
        }}
      >
        <Container
          style={{
            position: 'relative',
            background: 'white',
            height: imageActive ? '100%' : '70%',
            transition: 'height 500ms'
          }}
        >
          {
            piece && <ImageLoader gray style={{ position: 'absolute', top: '20px', height: '80%', maxWidth: '90%', objectFit: 'contain' }} src={piece.image} alt='image display' />
          }
          <Container
            onClick={() => setImageActive(imageActive => !imageActive)}
            style={{
              bottom: '30px',
              height: '60px',
              width: '60px',
              fontSize: '32px',
              fontFamily: 'Oswald',
              transform: `rotate(90deg) scaleX(${imageActive ? -1 : 1})`,
              transition: 'transform 500ms ease-out'
            }}
          >
            {'>'}
            {/*<img src='/images/icons/downarrow.png' style={{ height: '40px', objectFit: 'contain', filter: 'invert(50%)' }} alt='save' />*/}
          </Container>
        </Container>
        <Container
          style={{
            position: 'relative',
            height: imageActive ? '0px' : '140px',
            opacity: imageActive ? 0 : 1,
            overflow: 'hidden',
            flexFlow: 'column',
            background: 'white',
            justifyContent: 'flex-start',
            transition: 'all 500ms'
          }}
        >
          <Container
            style={{
              position: 'relative',
              height: '20px',
              fontSize: '18px'
            }}
          >
            {piece && piece.seasonName}
          </Container>
          <Container
            style={{
              position: 'relative',
              height: '80px',
              width: '80%',
              maxWidth: '300px'
            }}
          >
            <Container
              style={{
                height: '50px',
                fontSize: '28px'
              }}
            >
              {piece && piece.name}
            </Container>
          </Container>
          <Container
            style={{
              position: 'relative',
              height: '40px',
              width: '80%',
              maxWidth: '300px'
            }}
          >
            <Container
              style={{
                height: '30px',
                fontSize: '22px'
              }}
            >
              {piece && (piece.price ? `$${piece.price}` : '')}
            </Container>
          </Container>
        </Container>
        <Container
          style={{
            right: '0px',
            top: '0px',
            height: '80px',
            width: '120px',
          }}
        >
          <Container
            onClick={handleClick}
            style={{
              height: '60px',
              pointerEvents: (piece && piece.sold) ? 'none' : 'auto',
              fontSize: '22px'
            }}
          >
            {(piece && piece.sold) ?
              <Container
                style={{
                  opacity: (piece && piece.sold && !imageActive) ? 1 : 0,
                  transition: 'opacity 250ms ease-out',
                  pointerEvents: 'none',
                  //top: '50px',
                  //left: '75px',
                  //width: '100px',
                  //height: '75px'
                }}
              >
                <img src='/images/icons/sold.png' style={{ height: '50px', objectFit: 'contain' }} />
              </Container> :
              (inCart ? '- COPPED' : '+ CART')
            }

          </Container>
        </Container>
        <Container
          onClick={goBack}
          style={{
            top: '0px',
            left: '0px',
            width: '80px',
            height: '80px',
            opacity: imageActive ? 0 : 1,
            pointerEvents: imageActive ? 'none' : 'auto',
            fontFamily: 'Oswald',
            fontSize: isMobile ? '36px' : '32px',
            color: '#808080',
            transition: 'opacity 250ms ease-out'
          }}
        >
          {
            //<img src='/images/icons/downarrow.png' style={{ height: '50px', width: '30px', filter: 'invert(50%)', transform: 'rotate(90deg)' }} alt='save' />
          }
          {'<'}
        </Container>
        <Container
          style={{
            paddingBottom: '50px',
            opacity: justAdded ? 1 : 0,
            background: 'rgba(255,255,255,0.5)',
            transform: `scale(${justAdded ? 1.2 : 1})`,
            pointerEvents: 'none',
            transition: 'all 250ms ease-out',
            fontSize: '350px',
            color: '#338500'
          }}
        >
          $
          </Container>
      </Container>
    </Container>
  )
}


function Shop({ pieces, cart, addToCart, removeFromCart }) {
  const { width, height } = useDocDimensions()

  const [selectedPiece, setSelectedPiece] = useState(null)
  const [showImage, setShowImage] = useState(false)
  //
  const galleryColumns = isMobile ? 3 : 5
  const imageLength = width / galleryColumns

  const handlePieceSelect = image => {
    if (image) {
      setSelectedPiece(image)
      setShowImage(true)
    }
    else {
      setShowImage(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 1)
  }, [])
  //console.log(cart)
  //console.log(props.top)
  //console.log(active)
  return (
    <Container
      style={{
        zIndex: selectedPiece ? '3' : '0',
      }}
    >
      <Container
        style={{
          position: 'fixed',
          top: '0px',
          height: isMobile ? '80px' : '100px',
          fontSize: isMobile ? '24px' : '32px',
          fontFamily: 'Oswald',
          color: '#808080'
        }}
      >
        SHOP
        </Container>
      <Container
        style={{
          alignItems: 'flex-start',
          overflowY: 'auto',
          top: isMobile ? '80px' : '100px',
          height: isMobile ? height - 80 : height - 100
        }}
      >
        <Container
          style={{
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',

            height: `${(imageLength + 2) * Math.max(pieces.length / galleryColumns, 1)}`
          }}
        >
          {pieces.map((piece, index) =>
            <Container
              onClick={() => handlePieceSelect(piece)}
              key={`tile_gallery_image_${index}`}
              style={{
                position: 'relative',
                minWidth: `${imageLength - 2}px`,
                minHeight: `${imageLength - 2}px`,
                width: `${imageLength - 2}px`,
                height: `${imageLength - 2}px`,
                //border: '1px solid #efefef',
                margin: '1px',
                background: 'white',
                overflow: 'hidden'
              }}
            >
              <ImageLoader gray src={piece.image} />
              {/*<img src={piece.image} alt={piece.name} style={{ height: '100%', maxWidth: '100%', objectFit: 'contain' }} />*/}
              <Container
                style={{
                  opacity: (cart && (cart.indexOf(piece) !== -1)) ? 1 : 0,
                  transform: `scale(${cart && ((cart.indexOf(piece) !== -1) ? 1 : 0.8)})`,
                  transition: 'all 250ms ease-out',
                  fontFamily: 'Oswald',
                  pointerEvents: 'none',
                  fontSize: '72px',
                  color: '#338500'
                }}
              >
                $
            </Container>
              <Container
                style={{
                  opacity: piece.sold ? 1 : 0,
                  pointerEvents: 'none',
                  bottom: '10px',
                  right: '10px',
                  width: '70px',
                  height: '50px'
                }}
              >
                <img src='/images/icons/sold.png' style={{ transform: 'rotate(-20deg)', height: '30px', objectFit: 'contain' }} />
              </Container>
            </Container>
          )}
        </Container>
      </Container>

      <SelectedShopPiece
        goBack={() => setSelectedPiece(null)}
        addToCart={() => addToCart(selectedPiece)}
        removeFromCart={() => removeFromCart(selectedPiece)}
        piece={selectedPiece}
        inCart={cart && (cart.indexOf(selectedPiece) !== -1)}
      />
    </Container>
  )
}

export default Shop  