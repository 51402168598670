import React, { useState, useEffect, useRef } from 'react'

function useLoadingDots(loading) {

    const [numDots, setNumDots] = useState(1)

    const dotInterval = useRef()

    const handleNextDot = () => {
        setNumDots(numDots => numDots % 3 + 1)
    }

    useEffect(() => {
        if (loading) {
            dotInterval.current = setInterval(handleNextDot, 333)
        }
        else {
            setTimeout(() => clearInterval(dotInterval.current), 500)
        }
    }, [loading])

    return numDots
}

export default useLoadingDots